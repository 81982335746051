import React, { useEffect, useState } from "react";
import { Layout } from "../../../../components/Layout/Layout";
import BackButton from "../../../../assets/backButton.png";
import { useNavigate, useParams } from "react-router-dom";
import terminatedIcon from "../../../../assets/icons/terminatedIcon.png";
import { Button } from "../../../../components/Button/Button";
import NewPaymentModal from "./NewPaymentModal";
import TerminationModal from "./TrminationModal";
import { http } from "../../../../utils/httpCommon";
import { prettyNumber } from "../../../../lib/prettyNumber";
import LoadingCircle from "../../../../components/LoadingCircle/LoadingCircle";
import moment from "moment";
import { useAuthUser } from "react-auth-kit";
import ModalComponent from "../../../../components/Modal/Modal";
import { Link } from "react-router-dom";
import { red, blue, green } from "@mui/material/colors";
import whiteEdit from "../../../../assets/icons/whiteEdit.png";

import EditRentContractModal from "../NewRentContractModal/EditRentContractModal";

const RentDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const params = useParams();
  const auth = useAuthUser();
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await http.get(`rent-contracts/${params.id}`);
      setData(res?.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();
  const [openNewPaymentModal, setOpenNewPaymentModal] = useState(false);

  const handleNewPaymentModal = () => {
    setOpenNewPaymentModal(true);
  };

  const [openTerminationModal, setOpenTerminationModal] = useState(false);

  const handleTerminationModal = () => {
    setOpenTerminationModal(true);
  };

  const [openEditModal, setOpenEditModal] = useState(false);
  const handleEditModal = () => {
    setOpenEditModal(true);
  };

  let sum = data?.transactions?.reduce((accumulator, object) => {
    return accumulator + object.amount;
  }, 0);

  const [image, openImage] = useState();

  const statusButtonStyles = {
    pending:
      " text-[#797979]  text-[16px] w-fit bg-[#EFEFEF] font-semibold p-2  justify-center items-center rounded-[25px] capitalize",
    active:
      " text-[#34B2C0]  text-[16px] w-fit bg-[#3bb2c0] bg-opacity-20 font-semibold p-2  justify-center items-center rounded-[25px] capitalize",
    cancelled:
      " text-red-500   text-[16px] w-fit bg-[#EFEFEF] font-semibold p-2  justify-center items-center rounded-[25px] capitalize",
    completed:
      " text-[#00AD3B]  text-[16px] w-fit bg-[#00ad3b33] bg-opacity-20 font-semibold p-2  justify-center items-center rounded-[25px] capitalize",
  };

  return (
    <>
      <NewPaymentModal
        data={data}
        fetchData={fetchData}
        opened={openNewPaymentModal}
        setOpened={setOpenNewPaymentModal}
      />
      <TerminationModal
        data={data}
        fetchData={fetchData}
        opened={openTerminationModal}
        setOpened={setOpenTerminationModal}
      />
      <EditRentContractModal
        opened={openEditModal}
        setOpened={setOpenEditModal}
        fetchMainData={fetchData}
      />
      {isLoading ? (
        <LoadingCircle />
      ) : (
        <Layout>
          <div className="bg-white min-h-screen p-[5%] mx-[5%]">
            <DisplayImage opened={Boolean(image)} setOpened={openImage} img={image} />
            <button
              onClick={() => navigate(-1)}
              className="flex justify-start w-full  gap-2  items-center"
            >
              <img
                className="w-[35px] h-[35px] object-contain"
                src={BackButton}
                alt="back-button"
              />
              Back
            </button>

            <div className="p-4 gap-[40px] items-center">
              <div>
                {auth().role === "Experience Officer" ||
                auth().role === "Branch Manager" ||
                auth().role === "Area Manager" ||
                auth().role === "Land Lord" ? null : (
                  <div>
                    <button
                      onClick={() => handleTerminationModal()}
                      className="flex items-center mb-3"
                    >
                      <img src={terminatedIcon} alt="Icon" className="w-[34px] h-[34px]" />
                      <span className="mx-[15px] text-[#686868] font-semibold">
                        {" "}
                        Terminate Contract
                      </span>
                    </button>
                  </div>
                )}

                <div>
                  <button onClick={() => handleEditModal()} className="flex items-center mb-3">
                    <img
                      src={whiteEdit}
                      alt="Icon"
                      className="w-[34px] h-[34px]"
                      style={{ backgroundColor: "#000", padding: "3px", borderRadius: "5px" }}
                    />
                    <span className="mx-[15px] text-[#686868] font-semibold">Edit contract</span>
                  </button>

                  <hr />
                </div>

                {/* Contract info */}
                <div className="mt-[15px] flex flex-col ">
                  {/* Company info */}
                  <h2 className="text-[#797979] text-[20px] font-bold mb-3">
                    Contract Information (<b className="text-[#011516]">#{data.rentNumber}</b> )
                    &nbsp;
                    <span className={statusButtonStyles[data?.status]}>{data?.status}</span>
                  </h2>

                  {/* Duration info */}
                  <div className="flex items-start text-[18px] gap-2 text-black mb-3">
                    <div className="flex items-start text-[18px] gap-2 text-black mb-3">
                      <img
                        src={data?.company?.logo}
                        alt="Facility image"
                        className="w-[40px] h-[40px] mt-1"
                        style={{ borderRadius: "30px", border: "1px solid red" }}
                      />
                      <span className="text-[#011516] text-[24px] font-bold pt-2">
                        <Link
                          to={`/community/company/${data?.company?._id}`}
                          className="gap-[10px] items-center  hover:text-[#34B2C0]"
                        >
                          {data?.company?.name}
                        </Link>
                        <br />

                        <span className="text-[#797979] text-[14px]" style={{ marginTop: "-3px" }}>
                          Leader (
                          <b className="text-[#011516]">
                            <Link
                              to={`/community/client/${data?.company?.leader?._id}`}
                              className="gap-[10px] items-center  hover:text-[#34B2C0]"
                            >
                              {data?.company?.leader?.name || "N/A"}
                            </Link>
                          </b>
                          )
                        </span>
                      </span>
                    </div>

                    <div className="ml-auto text-right gap-2 text-black mb-3">
                      <span>Rented for {data?.duration} months</span>
                      <br />
                      <span
                        className="text-[#011516] text-[20px]"
                        style={{ fontFamily: "gilroy-bold" }}
                      >
                        {moment(data?.startDate).format(" Do MMM  YYYY")} -{" "}
                        {moment(data?.endDate).format(" Do MMM  YYYY")}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Notes */}
                <div className="p-2">
                  <div className=" mx-4 ">
                    <div className="text-[#011516] text-[20px] font-bold  mt-[20px] ">Notes</div>
                    {data?.notes && (
                      <div className="text-[18px] text-[#797979] ">{data?.notes}</div>
                    )}
                  </div>
                </div>

                <hr />

                {/* Rented facilities */}
                <div className="mt-[15px] flex flex-col mb-3">
                  <div
                    className="text-[#011516] text-[18px] "
                    style={{ fontFamily: "gilroy-bold" }}
                  >
                    Facilities
                  </div>
                  <div className="flex gap-3 flex-nowrap overflow-x-auto">
                    <ul>
                      {data?.facility?.map((singlefacility, i) => {
                        return (
                          <li style={{ paddingTop: 7 }}>
                            <Link
                              to={`/inventory/facility/${singlefacility._id}`}
                              className="flex gap-[10px] items-center  hover:text-[#34B2C0]"
                            >
                              <span className="text-[16px]">
                                <div className="flex items-start text-[18px]  gap-2 text-black">
                                  <img
                                    src={singlefacility.mainImage}
                                    alt="Facility image"
                                    className="w-[40px] h-[40px] mt-1"
                                    style={{ borderRadius: "30px", border: "1px solid red" }}
                                  />

                                  <span style={{ paddingTop: 10 }}>
                                    <b style={{ fontWeight: 900 }}>{singlefacility.name}</b>
                                    &nbsp; ({prettyNumber(singlefacility.rentPricePerMonth)}{" "}
                                    EGP/month)
                                  </span>
                                </div>
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>

                <hr />

                {/* Finance info */}
                <div className="mt-[15px] flex flex-col ">
                  <h2 className="text-[#797979] text-[20px] font-bold mb-3">Financial facts</h2>

                  <div className="items-start text-[18px] gap-2 text-black mb-3">
                    <div className="flex items-start text-[18px] gap-2 text-black mb-3">
                      {/* Total */}
                      <span className="text-[#011516] text-[16px] font-bold pt-2">
                        Rent Price:
                        <br />
                        <span className="text-[#797979] text-[18px]" style={{ marginTop: "-3px" }}>
                          <b className="text-[#011516]">{prettyNumber(data?.total)} EGP</b>
                        </span>
                      </span>

                      {/* Insurance */}
                      <span className="text-[#011516] ml-auto text-[16px] font-bold pt-2">
                        Insurance:
                        <br />
                        <span className="text-[#797979] text-[18px]" style={{ marginTop: "-3px" }}>
                          <b className="text-[#011516]">{prettyNumber(data?.insurance || 0)} EGP</b>
                        </span>
                      </span>

                      {/* Payment dates */}
                      <span className="text-[#011516] ml-auto text-[16px] font-bold pt-2">
                        Payment Dates:
                        <br />
                        <div>
                          <ul>
                            {data?.paymentsDates?.map((paymentDate) => {
                              const isUnpaid =
                                paymentDate?.receivableCreated && paymentDate.status == "failed";
                              const color =
                                paymentDate.status === "pending"
                                  ? blue[500]
                                  : paymentDate.status === "paid"
                                    ? green[500]
                                    : red[500];

                              return (
                                <li key={paymentDate._id}>
                                  <span>
                                    {moment(paymentDate.date).format(" Do MMM  YYYY")} &nbsp;
                                    <span
                                      className="space-x-2"
                                      style={{ color, textTransform: "capitalize" }}
                                    >
                                      <span className="text-black font-semibold text-[18px]">
                                        ({paymentDate?.installment} EGP)
                                      </span>
                                      <span>({isUnpaid ? "Unpaid" : paymentDate.status})</span>
                                    </span>
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>

                {/* Images */}
                {data?.images?.length > 0 && (
                  <div className="mt-[15px] flex flex-col pb-2">
                    <div
                      className="text-[#011516] text-[18px] "
                      style={{ fontFamily: "gilroy-bold" }}
                    >
                      Images
                    </div>
                    <div className="flex gap-3 flex-nowrap overflow-x-auto">
                      {data?.images?.map((img, i) => {
                        return (
                          <img
                            key={i}
                            className="w-52 h-60 object-contain"
                            src={img}
                            alt=""
                            onClick={() => openImage(img)}
                          ></img>
                        );
                      })}
                      {!data?.images?.length ? "No images" : null}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <hr />

            <div className="mt-4">
              <div className="mt-[15px]">
                <div
                  className=" text-[#011516] text-[18px] font-bold  mb-4"
                  style={{ fontFamily: "gilroy-bold" }}
                >
                  Total
                </div>
                <div className="bg-[#F6F8F7] w-full rounded-[10px]  flex flex-col p-[16px] gap-2 ">
                  <div className="flex justify-between">
                    <span className="font-bold text-[16px]">Rent Price</span>
                    <span className="text-[#797979] text-[16px]">
                      {prettyNumber(+data?.rentTotal)} EGP
                    </span>
                  </div>

                  {data?.discount && Number(data?.discount) > 0 ? (
                    <div className="flex justify-between">
                      <span className="font-bold text-[16px]">Discount</span>
                      <span className="text-[#797979] text-[16px]">
                        -{prettyNumber(+data?.discount)} EGP
                      </span>
                    </div>
                  ) : null}

                  <div className="flex justify-between text-[16px]">
                    <span className="font-bold">WHT</span>
                    <span className="text-[#797979] ">{prettyNumber(+data?.whtAmount)} EGP</span>
                  </div>

                  <div className="flex justify-between text-[16px]">
                    <span className="font-bold">VAT</span>
                    <span className="text-[#797979] ">{prettyNumber(data?.taxAmount)} EGP</span>
                  </div>

                  {/* {data?.securityDeposit && Number(data?.securityDeposit) > 0 ? (
                    <div className="flex justify-between text-[16px]">
                      <span className="font-bold">Security Deposit Amount</span>
                      <span className="text-[#797979] ">
                        {Number(data?.securityDeposit).toFixed(2)}
                        EGP
                      </span>
                    </div>
                  ) : null} */}

                  {data?.insurance && !isNaN(data?.insurance) && Number(data?.insurance) > 0 ? (
                    <div className="flex justify-between text-[16px]">
                      <span className="font-bold">Insurance </span>
                      <span className="text-[#797979] ">{prettyNumber(data?.insurance)} EGP</span>
                    </div>
                  ) : null}

                  <div className="flex flex-col gap-4 mt-2">
                    <span className="font-bold text-[18px]">Total</span>
                    <span
                      className="text-[40px] text-[#011516]"
                      style={{ fontFamily: "gilroy-bold" }}
                    >
                      {prettyNumber(+data?.total)} EGP
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Transactions table */}
            <div className="px-10 p-3">
              <div
                className=" text-[#011516] text-[20px] font-bold  mb-2"
                // style={{ fontFamily: "gilroy-bold" }}
              >
                List of installments
              </div>
              <table className=" w-full">
                <thead className="text-left w-full text-[18px]">
                  <tr>
                    <th className="w-fit px-2 py-5">Amount</th>
                    <th className="w-fit px-2 py-5">Time Stamp</th>
                    <th className="w-fit px-2 py-5">Description</th>
                    <th className="w-fit px-2 py-5">Payment Method</th>
                  </tr>
                </thead>
                <tbody className="text-left text-[16px] bg-[#F6F8F7]  rounded-[10px]  w-full">
                  {data?.transactions?.map((t) => (
                    <tr className="" key={t._id}>
                      <td className="w-fit px-2 py-2">{prettyNumber(+t?.amount)} EGP</td>
                      <td className="w-fit px-2 py-2"> {moment(t?.date).format("LLLL")}</td>
                      <td className="w-fit px-2 py-2">{t?.description ? t?.description : ""} </td>
                      <td className="w-fit px-2 py-2">
                        {t?.paymentMethodName ? t?.paymentMethodName : ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className=" mt-3 bg-[#F6F8F7] w-full rounded-[10px]  flex  items-center justify-between p-[20px] gap-2 ">
                <div className="flex flex-col">
                  <span className="font-bold text-[18px]">
                    Amount left
                    {data?.isReceivable ? (
                      <span
                        className="text-[#011516] text-[18px] "
                        style={{ fontFamily: "gilroy-bold" }}
                      >
                        &nbsp; (Unpaid contract)
                      </span>
                    ) : null}
                  </span>
                  <span
                    className="text-[#011516] text-[40px] "
                    style={{ fontFamily: "gilroy-bold" }}
                  >
                    {data?.total <= sum ? "Completed" : prettyNumber(data?.total - sum) + " EGP"}
                  </span>
                </div>
                <div>
                  {data?.total <= sum
                    ? null
                    : auth().role !== "Land Lord" &&
                      !data?.isReceivable && (
                        <Button onClick={() => handleNewPaymentModal()} secondary={true}>
                          <span className="font-bold">Pay Amount</span>
                        </Button>
                      )}
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default RentDetails;

function DisplayImage({ img, opened, setOpened }) {
  return (
    <ModalComponent size={"100%"} opened={opened} setOpened={setOpened}>
      <div className="flex justify-center flex-col items-center gap-[25px]">
        <img className="w-full h-full max-w-[80%] max-h-[80%]" src={img} alt="" />
        <div className="flex  gap-[40px]">
          <Button onClick={() => setOpened("")} secondary={true} invert={true}>
            Close
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
}
