import React from "react";
import InputBase from "@material-ui/core/InputBase";
import { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { Button } from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { http } from "../../../utils/httpCommon";
import { prettyNumber } from "../../../lib/prettyNumber";
import { useEffect } from "react";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import VasCard from "./VasCard";
import emptyCart from "../../../assets/emptyCart.svg";
import { useVasCart } from "../../../Context/VasCart";
import ConfirmVasOrder from "./ConfirmVasOrder";
import { useAuthUser } from "react-auth-kit";
import { ADMIN_ROLES } from "../../../lib/enums";
import MenuItem from "@mui/material/MenuItem";
import { TextInput } from "../../../components/TextInput/TextInput";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

const Vas = () => {
  const navigator = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const {
    cartItems,
    removeAllFromCart,
    discountValue,
    discountType,
    changeDiscountType,
    changeDiscountValue,
  } = useVasCart();

  const [status, setStatus] = useState("checked-in");
  const [vasData, setVasData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getVasData = async () => {
    try {
      const res = await http.get(`vas?page=1&limit=10`);
      setVasData(res?.data?.data);
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVasData();
  }, []);

  const calculateTotalPrice = () => {
    let subtotal = 0;
    let discountAmount = 0;
    let vat = 0;
    let total = 0;

    for (let i = 0; i < cartItems.length; i++) {
      subtotal += cartItems[i]?.price * cartItems[i]?.quantity;
    }

    if (discountValue > 0) {
      if (discountType === "percent") {
        discountAmount = Math.ceil(((subtotal * discountValue) / 100).toFixed(2));
      } else {
        discountAmount = Math.ceil(+Number(discountValue).toFixed(2));
      }
    }

    const totalAfterDiscountAmount = subtotal - discountAmount;
    vat = Math.ceil((totalAfterDiscountAmount * 0.14).toFixed(2));

    total = totalAfterDiscountAmount + vat;

    return {
      subtotal,
      discountAmount,
      vat,
      total,
    };
  };

  const [pricingData, setPricingData] = useState(calculateTotalPrice());

  useEffect(() => {
    setPricingData(calculateTotalPrice());
  }, [cartItems]);

  useEffect(() => {
    setPricingData(calculateTotalPrice());
  }, [discountType]);

  useEffect(() => {
    setPricingData(calculateTotalPrice());
  }, [discountValue]);

  const ClearOrder = () => {
    removeAllFromCart();
  };
  const auth = useAuthUser();

  const [openVasConfirmModal, setOpenVasConfirmModal] = useState(false);
  const [discountTypeFiled, setDiscountType] = useState(discountType);
  const [discoutValueFiled, setDiscountValue] = useState(discountValue);

  return (
    <div className="mt-2">
      <ConfirmVasOrder opened={openVasConfirmModal} setOpened={setOpenVasConfirmModal} />
      {loading && !vasData ? (
        <LoadingCircle />
      ) : (
        <div className="relative flex">
          <div className="w-[75%]">
            <div className="flex items-center gap-6 ">
              <div className="  flex gap-[10px] items-center ">
                <InputBase
                  className="border-[1px] border-[#DEDEDE] rounded-[10px] h-[45px]  min-w-[400px] "
                  value={searchQuery}
                  onChange={handleChange}
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  startAdornment={<SearchIcon className="mx-[20px]" />}
                />
              </div>
              <div className="flex w-full justify-between ">
                <div className="flex gap-[10px] items-center">
                  {auth().role === ADMIN_ROLES.SA && (
                    <Button
                      onClick={() => navigator("/operations/vas/add-new-vas")}
                      secondary={true}
                      classes={"h-[58px] min-w-fit"}
                    >
                      <span className="text-[16px]">Add new VAS</span>
                    </Button>
                  )}
                  <div className="flex flex-row gap-[10px]  font-bold  ">
                    <button
                      onClick={() => {}}
                      className={`min-w-[100px] h-[58px] rounded-[23px] text-[15px] px-2 ${
                        status === "checked-in"
                          ? "text-[#F6F8F7] bg-[#34B2C0]  "
                          : " text-[#797979] bg-transparent border-[1px] border-[#797979] "
                      }  `}
                    >
                      Product
                    </button>
                    <button
                      onClick={() => {}}
                      className={`min-w-[120px] h-[58px] rounded-[23px] text-[15px] px-2 ${
                        status === "checked-out"
                          ? "text-[#F6F8F7] bg-[#34B2C0]  "
                          : " text-[#797979] bg-transparent border-[1px] border-[#797979] "
                      }  `}
                    >
                      One time service
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* data */}
            <div className="flex flex-wrap gap-2 mt-4">
              {vasData?.map((vasItem) => (
                <div key={vasItem?._id} className="">
                  <VasCard data={vasItem} fetchData={getVasData} />
                </div>
              ))}
            </div>
          </div>
          {/* Cart */}
          <div
            className={`w-[25%] fixed  flex flex-col ${
              cartItems?.length > 0
                ? "pt-20 justify-start items-start "
                : "justify-center items-center"
            } right-0 top-0 bg-white h-[100%]`}
          >
            {cartItems?.length > 0 ? (
              <div className="flex w-full flex-col h-full justify-between pb-12 px-4">
                <div className="flex flex-col gap-3 w-full overflow-y-auto ">
                  {cartItems?.map((item) => (
                    <div
                      className="border-[1px] border-[#E4E4E4] rounded-[10px] p-3 flex items-center gap-4"
                      id={item._id}
                    >
                      <img
                        src={item?.image}
                        className="w-[100px] object-cover rounded-[10px]"
                        alt={item?.name}
                      />
                      <div className="flex flex-col ">
                        <span className="text-[#797979] font-semibold text-[16px]">
                          {item?.name}
                        </span>
                        <span
                          className=" text-[#011516]   text-[25x]"
                          style={{ fontFamily: "gilroy-bold" }}
                        >
                          {prettyNumber(+item?.price)} EGP
                        </span>
                        <span className="text-[#797979]  text-[16px]">
                          {item?.quantity} {item?.name}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>

                <div>
                  {/* Discount */}
                  <div className="border-[1px] border-[#E4E4E4] rounded-[10px] mb-3">
                    <div className="  py-2 px-3 w-full rounded-[10px] flex flex-col">
                      <div className="flex gap-[10px]">
                        {/* {auth()?.role !== "Experience Officer" ? ( */}
                        <div className="flex flex-col gap-4 items-center flex-1">
                          <div className="w-full">
                            <InputLabel>Discount Type</InputLabel>
                            <Select
                              sx={{
                                color: "black",
                                ".MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#EFEFEF",
                                  width: "100%",
                                },
                              }}
                              className="w-full"
                              // name={name}
                              // onBlur={onBlur}
                              onChange={(e, { props }) => {
                                changeDiscountType(props.value);
                                setDiscountType(props.value);
                              }}
                              // variant="outlined"
                              // labelId="discount label"
                              // id="discount-type"
                              value={discountTypeFiled}
                              // label="discount-type"
                              // inputRef={ref}
                            >
                              <MenuItem value={"percent"}>Percentage</MenuItem>
                              <MenuItem value={"amount"}>Amount</MenuItem>
                            </Select>
                          </div>
                          <TextInput
                            label={`Discount ${discountTypeFiled === "percent" ? "%" : "EGP"}`}
                            maxLength={discountTypeFiled === "percent" ? 3 : undefined}
                            value={discoutValueFiled}
                            onChange={(e) => {
                              let value = e.target.value.replace(/[^0-9]/g, ""); // Only numbers allowed
                              if (discountTypeFiled === "percent" && Number(value) > 100) return;
                              changeDiscountValue(value);
                              setDiscountValue(value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-[1px] border-[#E4E4E4] rounded-[10px] ">
                    <div className="  py-2 px-3 w-full rounded-[10px] flex flex-col">
                      <div
                        className="text-[#011516] text-[18px]"
                        style={{ fontFamily: "gilroy-bold" }}
                      >
                        Total
                      </div>
                      <div className="flex ">
                        <span className="w-[80px] text-[18px] font-bold">Subtotal</span>
                        <span className="text-[18px] text-[#797979]">
                          {prettyNumber(pricingData?.subtotal || 0)} EGP
                        </span>
                      </div>
                      {pricingData?.discountAmount > 0 && (
                        <div className="flex ">
                          <span className="w-[80px] text-[18px] font-bold">Discount</span>
                          <span className="text-[18px] text-[#797979]">
                            - {prettyNumber(pricingData?.discountAmount || 0)} EGP
                          </span>
                        </div>
                      )}
                      <div className="flex ">
                        <span className="w-[80px] text-[18px] font-bold">VAT</span>
                        <span className="text-[18px] text-[#797979]">
                          {prettyNumber(pricingData?.vat || 0)} EGP
                        </span>
                      </div>
                      <div className="mt-3  text-[#797979] text-[16px]">total</div>
                      <div className="flex items-center text-[18px]  gap-2">
                        <div
                          className="text-[#011516] text-[25px] "
                          style={{ fontFamily: "gilroy-bold" }}
                        >
                          {prettyNumber(pricingData?.total || 0)} EGP
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-3 justify-end mt-4">
                    <button
                      onClick={ClearOrder}
                      className=" inline-flex  bg-transparent items-center justify-center rounded-md text-sm  ring-offset-background 
                                  transition-colors focus-visible:outline-none focus-visible:ring-2
                                  focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50
                                text-[black] hover:bg-[#000000]/90h-10 px-4 py-2 font-semibold"
                    >
                      Clear order
                    </button>
                    <button
                      onClick={() => setOpenVasConfirmModal(true)}
                      className=" inline-flex items-center justify-center rounded-md text-sm  ring-offset-background 
                                  transition-colors focus-visible:outline-none focus-visible:ring-2
                                  focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50
                                bg-[#000000] text-[white] hover:bg-[#000000]/90h-10 px-4 py-2 font-semibold"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <img className="w-[150px]" src={emptyCart} alt="empty cart" />
                <div className="text-[#797979] mt-2 font-extrabold text-[16px]">
                  No products added yet.
                </div>
                <div className="text-[#797979]  text-[16px]">Add products to the order</div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Vas;
