import React, { useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Button } from "../../../../components/Button/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Carousel from "react-grid-carousel";
import { components as comp } from "react-select";

import AsyncSelect from "react-select/async";
import { http } from "../../../../utils/httpCommon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker, DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { convertTimeStringToDate } from "../../../../utils/convertTimeStringToDate";
import { toast } from "react-hot-toast";
import CalendarFormStep2 from "./CalendarFormStep2";
import stopIcon from "../../../../assets/stop.svg";
import { SelectedLocationContext } from "../../../../Context/LocationProvider";
import { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FormControl, Switch } from "@mui/material";
import { TextInput } from "../../../../components/TextInput/TextInput";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";
import { debounce } from "lodash";
import { prettyNumber } from "../../../../lib/prettyNumber.js";
import dayjs from "dayjs";

const PREFIX = "Demo";

export const classes = {
  content: `${PREFIX}-content`,
  header: `${PREFIX}-header`,
  closeButton: `${PREFIX}-closeButton`,
  buttonGroup: `${PREFIX}-buttonGroup`,
  button: `${PREFIX}-button`,
  picker: `${PREFIX}-picker`,
  wrapper: `${PREFIX}-wrapper`,
  icon: `${PREFIX}-icon`,
  textField: `${PREFIX}-textField`,
  addButton: `${PREFIX}-addButton`,
};
export function diff_hours(dt2, dt1) {
  var diff = (new Date(dt2)?.getTime() - new Date(dt1)?.getTime()) / 1000;
  diff /= 60 * 30;
  return Math.abs(Math.ceil(Math.abs(diff)));
}

const CalendarForm = ({
  selectedTimeSlotData,
  open,
  close,
  facilities,
  paymentMethods,
  fetchAppointmentsDataAfterDelete,
}) => {
  const schema = yup
    .object({
      facilityId: yup.string().required("Facility  is required"),
      clientId: yup.object().typeError("Client is required").required("Client is required"),
      discountType: yup.string().required("Discount type is required"),
      wht: yup.number().default(0),
    })
    .required();
  const {
    handleSubmit,
    setValue,
    control,
    watch,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      discountType: "percentage",
    },
  });

  const discountTypeValue = useWatch({
    control,
    name: "discountType",
  });

  const WHTValue = useWatch({
    control,
    name: "wht",
  });

  const { selectedLocation } = useContext(SelectedLocationContext);

  const discount = +Number(watch("discount")).toFixed(2);
  const promoCode = watch("promoCode") || "";
  const clientId = watch("clientId");

  const setCustomValue = (id, value) => {
    setValue(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const [receivables, setReceivables] = useState(false);
  const [recievablesDate, setRecievablesDate] = useState(null);

  const [checkedFacility, setCheckedFacility] = useState("");
  const handleCheckedFacility = (facility) => {
    if (checkedFacility._id === facility._id) {
      setCheckedFacility("");
      setCustomValue("facilityId", "");
    } else {
      setCheckedFacility(facility);
      setCustomValue("facilityId", facility._id);
    }
  };
  const navigator = useNavigate();
  const CustomNoOptionsMessage = ({ inputValue, onResetClick }) => (
    <div className="p-2">
      No results found{" "}
      <button
        className=" text-[18px]  text-[#34B2C0]  hover:opacity-95"
        style={{ fontFamily: "gilroy-bold" }}
        onClick={() => navigator(`/community/clients/new-client`)}
      >
        Add new client
      </button>
    </div>
  );

  const loadOptions = async (inputText, callback) => {
    let res = null;
    if (inputText.length > 0) {
      let response = await http.get(`/client/find?q=${inputText}`);
      res = response.data.data;
      callback(
        res.map((i) => ({
          label: (
            <div key={`${i.phone}-${i?.name}`} className="flex items-center">
              {i?.profilePicture && (
                <img
                  src={i?.profilePicture}
                  className="w-[45px] h-[45px] object-cover rounded-full mr-2"
                  alt={i.name}
                />
              )}
              {i.name} / {i.phone}
            </div>
          ),
          value: i._id,
        })),
      );
    }
  };

  //   calender

  const [components, setComponents] = useState([
    {
      dateValue: selectedTimeSlotData.date ? dayjs(selectedTimeSlotData.date) : dayjs(),
      startTimeValue: selectedTimeSlotData.time ? dayjs(selectedTimeSlotData.time) : dayjs(),
      endTimeValue: selectedTimeSlotData.time
        ? dayjs(selectedTimeSlotData.time).add(1, "hours")
        : dayjs().add(1, "hours"),
    },
  ]);

  const [dateValue, setDateValue] = useState(
    selectedTimeSlotData.date ? dayjs(selectedTimeSlotData.date) : dayjs(),
  );
  const [startTimeValue, setStartTimeValue] = useState(
    selectedTimeSlotData.time ? dayjs(selectedTimeSlotData.time) : dayjs(),
  );
  const [endTimeValue, setEndTimeValue] = useState(
    selectedTimeSlotData.time
      ? dayjs(selectedTimeSlotData.time).add(1, "hours")
      : dayjs().add(1, "hours"),
  );

  const [overlayStep, setOverlayStep] = useState(0);
  const [responseData, setResponseDate] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const onSubmit = async (passedData) => {
    try {
      setSubmitLoading(true);
      const res = await http.post(
        `/bookings`,
        JSON.stringify({
          location: selectedLocation,
          facility: passedData.facilityId,
          notes: passedData.notes,
          user: passedData.clientId.value,
          days: components.map((c) => ({
            date: dayjs(c.dateValue).format("YYYY-MM-D"),
            startTime: dayjs(c.startTimeValue).unix(),
            endTime: dayjs(c.endTimeValue).unix(),
          })),
          wht: passedData.wht,
          discountType: discountTypeValue,
          discount: passedData.discount,
          promoCode: passedData.promoCode?.value || null,
          receivable: receivables,
          expectedDueDate:
            receivables && recievablesDate ? dayjs(recievablesDate).format("YYYY-MM-D") : undefined,
        }),
      );

      setOverlayStep(1);
      setResponseDate(res?.data);

      // fetchAppointmentsData();
      toast.success("Booking created successfully");
    } catch (error) {
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleAddComponent = () => {
    setComponents([
      ...components,
      {
        dateValue,
        startTimeValue,
        endTimeValue,
      },
    ]);
  };

  const handleDeleteComponent = (index) => {
    if (index === 0) {
      return; // Prevent deleting the first component
    }

    const updatedComponents = [...components];
    updatedComponents.splice(index, 1);
    setComponents(updatedComponents);
  };

  const bookingDuration = components.reduce(
    (prev, curr) => prev + diff_hours(curr?.endTimeValue, curr?.startTimeValue),
    0,
  );

  const [activePromoCodes, setActivePromoCodes] = useState([]);

  const fetchActivePromoCodes = async () => {
    try {
      const activePromoCodes = await http.get("/promo-codes/active");
      setActivePromoCodes(activePromoCodes.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedFetchActivePromoCodes = useCallback(debounce(fetchActivePromoCodes, 500), []);

  useEffect(() => {
    debouncedFetchActivePromoCodes();
  }, []);

  const loadPromoCodeOptions = async (inputValue) => {
    if (!inputValue) {
      return activePromoCodes.map((code) => ({
        label: code.name,
        value: code._id,
      }));
    }

    const filtered = activePromoCodes.filter((code) =>
      code.name.toLowerCase().includes(inputValue.toLowerCase()),
    );

    return filtered.map((code) => ({
      label: code.name,
      value: code._id,
    }));
  };

  const [pricing, setPricing] = useState({
    subTotal: null,
    vat: null,
    wht: null,
    totalWithVat: null,
    totalDiscounted: null,
    promoCodeAmount: null,
  });

  const calcPrice = async (
    checkedFacility,
    discount,
    bookingDuration,
    promoCode,
    discountTypeValue,
    WHTValue,
  ) => {
    if (!checkedFacility?._id) return false;
    try {
      const result = await http.get(
        `/bookings/calc?facility=${
          checkedFacility?._id
        }&discount=${discount}&duration=${bookingDuration}&wht=${WHTValue}&discountType=${discountTypeValue}&promoCodeId=${
          promoCode?.value || ""
        }`,
      );
      setPricing(result.data);
    } catch (error) {
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    }
  };

  const debouncedCalcPrice = useCallback(
    debounce(
      (checkedFacility, discount, bookingDuration, promoCode, discountTypeValue, WHTValue) =>
        calcPrice(
          checkedFacility,
          discount,
          bookingDuration,
          promoCode,
          discountTypeValue,
          WHTValue,
        ),
      500,
    ),
    [],
  );

  useEffect(() => {
    debouncedCalcPrice(
      checkedFacility,
      discount,
      bookingDuration,
      promoCode,
      discountTypeValue,
      WHTValue,
    );
  }, [checkedFacility?._id, discount, bookingDuration, promoCode, discountTypeValue, WHTValue]);

  const NoOptionsMessage = (props) => {
    return (
      <comp.NoOptionsMessage {...props}>
        <span className="custom-css-class">Search</span>
      </comp.NoOptionsMessage>
    );
  };

  const auth = useAuthUser();
  const discountList = [0, 5, 10, 15, 20, 25, 30];

  return (
    <div className="pb-4">
      {/* {overlayStep === 0 ? ( */}
      {overlayStep === 0 ? (
        <div className="py-0 px-4">
          <div className="flex justify-between mt-0">
            <div
              className="text-[32px] "
              style={{
                fontFamily: "gilroy-bold",
              }}
            >
              New Booking
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="  flex flex-col   ">
            <div className=" mt-2  gap-[2px] relative w-full h-fit pt-4 pb-2 bg-white  ">
              <div className="absolute bg-white py-1 -top-4 text-[16px]  font-bold ">
                Select Facility
              </div>

              <Carousel cols={2} rows={2} gap={5} loop style={{ padding: "50px" }}>
                {facilities?.data?.map((item, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <div
                        className={`  ml-3  p-1 cursor-pointer hover:bg-[#32b3be1a]  transition-all
                    ${checkedFacility._id === item._id ? "bg-[#32b3be1a] border-[#34B2C0]" : ""}
                      font-semibold
                      border-[1px] flex justify-start items-start py-2  ]
                      rounded-[10px] w-[200px] h-full `}
                        onClick={() => {
                          // item === checkedFacility
                          //   ? setValue("role", null)

                          handleCheckedFacility(item);
                        }}
                        // {...register("role", { required: "role is required" })}
                      >
                        <LazyLoadImage
                          height={70}
                          width={70}
                          src={item.mainImage}
                          alt={item.name}
                          className="w-[60px] h-[65px] object-cover rounded-[10px] "
                        />
                        {/* <img
                          height={70}
                          width={70}
                          loading="lazy"
                         
                        /> */}
                        <div className="flex flex-col justify-between   text-[14px]  h-full  mx-1">
                          <span className="font-bold ">{item.name}</span>
                          <span className="text-[#34B2C0]  text-[14px] underline ">Quick View</span>
                        </div>
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
              <p className="text-red-500 mt-1">{errors.facilityId?.message}</p>
            </div>

            <div className=" w-full bg-white py-1   text-[18px]  font-bold ">
              Client Information
            </div>
            <div className="w-full">
              <AsyncSelect
                isClearable
                defaultOptions
                components={{
                  NoOptionsMessage: ({ inputValue }) => (
                    <CustomNoOptionsMessage inputValue={inputValue} onResetClick={() => {}} />
                  ),
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: "56px",
                    borderRadius: "8px",
                    borderColor: "black",
                    "&:hover": {},
                  }),
                  option: (styles) => ({
                    ...styles,
                    zIndex: "999 !important",
                    backgroundColor: "white !important",
                  }),
                }}
                value={clientId}
                onChange={
                  (value) => {
                    setCustomValue("clientId", value);
                  }
                  // setSelectedUser(value)
                }
                placeholder={"Search for Client"}
                loadOptions={loadOptions}
              />
            </div>
            <p className="text-red-500 mt-2">{errors.clientId?.message}</p>

            <div className="w-full  mt- bg-white py-1  text-[18px]  font-bold ">Booking</div>

            <div className="border-[1px] border-[#79797933] rounded-[10px] px-2 pt-1 pb-4">
              {components.map((component, index) => (
                <div className="flex mt-4 items-center gap-3 " key={index}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date"
                      value={component.dateValue}
                      renderInput={(props) => <TextField className={classes.picker} {...props} />}
                      onChange={(newValue) => {
                        console.log("new value", newValue);
                        const updatedComponents = [...components];
                        updatedComponents[index].dateValue = newValue;
                        const currentDate = newValue?.format("YYYY-MM-DD");
                        console.log("current date", currentDate);
                        const startTimeHrs =
                          updatedComponents[index].startTimeValue?.format("THH:mm");
                        const endTimeHrs = updatedComponents[index].endTimeValue?.format("THH:mm");
                        const startTime = dayjs(currentDate + startTimeHrs);
                        const endTime = dayjs(currentDate + endTimeHrs);
                        updatedComponents[index].startTimeValue = startTime;
                        updatedComponents[index].endTimeValue = endTime;
                        console.log("updatedComponents", updatedComponents);
                        setComponents(updatedComponents);
                      }}
                    />

                    <TimePicker
                      label="From"
                      renderInput={(props) => <TextField className={classes.picker} {...props} />}
                      value={component.startTimeValue}
                      onChange={(newValue) => {
                        const updatedComponents = [...components];
                        const currentDate =
                          updatedComponents[index]?.dateValue?.format("YYYY-MM-DD");
                        const currentTime = newValue?.format("THH:mm");
                        const value = dayjs(currentDate + currentTime);

                        updatedComponents[index].startTimeValue = value;
                        setComponents(updatedComponents);
                      }}
                    />

                    <TimePicker
                      label="To"
                      renderInput={(props) => <TextField className={classes.picker} {...props} />}
                      value={component.endTimeValue}
                      onChange={(newValue) => {
                        const updatedComponents = [...components];
                        const currentDate =
                          updatedComponents[index].dateValue?.format("YYYY-MM-DD");
                        const currentTime = newValue?.format("THH:mm");
                        const value = dayjs(currentDate + currentTime);

                        updatedComponents[index].endTimeValue = value;
                        setComponents(updatedComponents);
                      }}
                    />
                  </LocalizationProvider>

                  {index > 0 && (
                    <div className="">
                      <img
                        src={stopIcon}
                        onClick={() => handleDeleteComponent(index)}
                        className="w-[30px] h-[30px] object-contain cursor-pointer"
                        alt="deleted"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div
              onClick={handleAddComponent}
              className="flex w-full justify-end text-[16px] mt-2 text-[#34B2C0]  cursor-pointer"
              style={{ fontFamily: "gilroy-bold" }}
            >
              + Add more days
            </div>
            <div className=" border-[#79797933] rounded-[10px] mt-4 px-2 pt-1 pb-4">
              <div className="flex justify-between items-center">
                <div className="w-full  my-2 bg-white py-1  text-[18px]  font-bold ">Unpaid</div>
                <Switch value={receivables} onChange={(_, checked) => setReceivables(checked)} />
              </div>
              {receivables && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Expected Due Date"
                    value={recievablesDate}
                    onChange={(newValue) => {
                      setRecievablesDate(newValue);
                    }}
                    renderInput={(props) => (
                      <TextField
                        className={classes.picker}
                        style={{ fontSize: "14px" }}
                        {...props}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            </div>

            <div className="mt-2 w-full">
              <div className="mt- bg-white py-1  text-[18px]  font-bold ">Notes</div>
              <textarea
                className="w-full"
                {...register("notes")}
                rows={5}
                style={{ borderRadius: 8, border: "1px solid black", padding: 8 }}
              ></textarea>
            </div>

            {/* Total */}
            {checkedFacility && (
              <div className="border-[1px] rounded-[10px]  mt-4 p-4">
                <div className="flex mt-4 gap-[5px] items-center w-full">
                  <FormControl sx={{ border: "none !important", minWidth: "200px", width: "100%" }}>
                    <InputLabel>WHT</InputLabel>
                    <Controller
                      control={control}
                      name="wht"
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <Select
                          sx={{
                            color: "black",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#EFEFEF",
                            },
                          }}
                          onBlur={onBlur} // notify when input is touched
                          onChange={onChange} // send value to hook form
                          variant="outlined"
                          labelId="wht-label"
                          id="wht"
                          // value={value}
                          label="wht"
                          inputRef={ref}
                          defaultValue=""
                        >
                          <MenuItem value={0}>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={0.01}>1%</MenuItem>
                          <MenuItem value={0.03}>3%</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </div>

                <div className="my-4">
                  <FormControl fullWidth sx={{ border: "none !important" }}>
                    <Controller
                      control={control}
                      name="promoCode"
                      render={({ field }) => (
                        <AsyncSelect
                          {...field}
                          isClearable
                          defaultOptions
                          loadOptions={loadPromoCodeOptions}
                          placeholder="Select Promo Code"
                          noOptionsMessage={() => {
                            if (activePromoCodes.length === 0) {
                              return "No valid promo codes available";
                            } else {
                              return "There is no matching promo codes !";
                            }
                          }}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              height: "56px",
                              borderRadius: "8px",
                              borderColor: "#EFEFEF",
                              "&:hover": {
                                borderColor: state.isFocused ? "#34B2C0" : "#EFEFEF",
                              },
                              boxShadow: state.isFocused ? "0 0 0 1px #34B2C0" : "none",
                            }),
                            option: (styles, { data, isFocused, isSelected }) => ({
                              ...styles,
                              backgroundColor: isFocused
                                ? "#F0F0F0"
                                : isSelected
                                  ? "#34B2C0"
                                  : null,
                              color: isSelected ? "white" : "black",
                              zIndex: 1,
                            }),
                          }}
                          formatOptionLabel={(option, { inputValue }) => {
                            const matches = option.label.match(new RegExp(inputValue, "gi"));
                            return (
                              <div>
                                {option.label
                                  .split(new RegExp(`(${inputValue})`, "gi"))
                                  .map((part, index) =>
                                    matches && matches.includes(part) ? (
                                      <strong key={index} style={{ color: "#34B2C0" }}>
                                        {part}
                                      </strong>
                                    ) : (
                                      part
                                    ),
                                  )}
                              </div>
                            );
                          }}
                          value={promoCode}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption || null);
                            setCustomValue("promoCode", selectedOption || null);
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </div>

                <div className="flex gap-[10px]">
                  {/* {auth()?.role !== "Experience Officer" ? ( */}
                  <div className="flex gap-4 items-center flex-1">
                    <FormControl fullWidth sx={{ border: "none !important" }}>
                      <TextInput
                        label={`Discount ${discountTypeValue === "percentage" ? "%" : "EGP"}`}
                        {...register("discount")}
                      />
                    </FormControl>
                    {/* Discount Type */}
                    <FormControl fullWidth sx={{ border: "none !important" }}>
                      <InputLabel>Discount Type</InputLabel>
                      <Controller
                        control={control}
                        name="discountType"
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                          <Select
                            sx={{
                              color: "black",
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#EFEFEF",
                              },
                            }}
                            name={name}
                            onBlur={onBlur}
                            onChange={(e, { props }) => {
                              onChange(props.value);
                            }}
                            variant="outlined"
                            labelId="discount label"
                            id="discount-type"
                            value={value}
                            label="discount-type"
                            inputRef={ref}
                          >
                            <MenuItem value={"percentage"}>Percentage</MenuItem>
                            <MenuItem value={"amount"}>Amount</MenuItem>
                          </Select>
                        )}
                      />
                      <p className="text-red-500 mt-1">{errors.discountType?.message}</p>
                    </FormControl>
                  </div>
                  {/* )
                   : (
                    <>
                      <FormControl fullWidth sx={{ border: "none !important" }}>
                        <InputLabel>Discount</InputLabel>
                        <Controller
                          control={control}
                          name="discount"
                          render={({ field: { onChange, onBlur, value, name, ref } }) => (
                            <Select
                              sx={{
                                color: "black",
                                ".MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#EFEFEF",
                                },
                              }}
                              name={name}
                              onBlur={onBlur}
                              onChange={(e, { props }) => {
                                onChange(props.value);
                              }}
                              variant="outlined"
                              labelId="discount label"
                              id="discount"
                              value={value}
                              label="discount"
                              inputRef={ref}
                            >
                              <MenuItem value={""}>
                                <em>None</em>
                              </MenuItem>
                              {discountList.map((d) => (
                                <MenuItem key={d} value={d}>
                                  {d}%
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </>
                  )} */}
                </div>
                <div
                  className="text-[16px] mt-2"
                  style={{ fontFamily: "gilroy-bold", width: "100%" }}
                >
                  Total Due
                </div>

                <div className="flex items-center gap-4 mt-1 max-w-[300px] justify-between">
                  <span className=" font-extrabold">Subtotal</span>
                  <div className="flex items-center">
                    <span className="text-[#797979] mx-[10px] ">
                      {prettyNumber(+pricing.subTotal)} EGP
                    </span>
                  </div>
                </div>

                {pricing.discountAmount && Number(pricing.discountAmount) > 0 ? (
                  <div className="flex items-center gap-4 mt-1 max-w-[300px] justify-between">
                    <span className="font-extrabold">Discount</span>
                    {/* <div className="flex items-center"> */}
                    <span className="text-[#797979] mx-[10px]">
                      -{prettyNumber(+pricing.discountAmount)} EGP
                    </span>
                    {/* </div> */}
                  </div>
                ) : null}

                {/* <div className="flex items-center gap-4 mt-1 max-w-[300px] justify-between"> */}

                {pricing.whtAmount ? (
                  <div className="flex items-center gap-4 mt-1 max-w-[300px] justify-between text-[16px]">
                    <span className="font-bold">WHT</span>
                    <span className="text-[#797979]  mx-[10px]">
                      {pricing.whtAmount}
                      EGP
                    </span>
                  </div>
                ) : null}
                {/* </div> */}
                {pricing.promoCodeAmount && Number(pricing.promoCodeAmount) > 0 ? (
                  <div className="flex items-center gap-4 mt-1 max-w-[300px] justify-between">
                    <div className="font-extrabold">Promo Code</div>
                    <div className="flex items-center">
                      <span className="text-[#797979] mx-[10px]">
                        -{prettyNumber(+pricing.promoCodeAmount)} EGP
                      </span>
                    </div>
                  </div>
                ) : null}

                <div className="flex items-center gap-4 mt-1 max-w-[300px] justify-between">
                  <span className=" font-extrabold">VAT</span>
                  <div className="flex items-center">
                    <span className="text-[#797979] mx-[10px] ">
                      {prettyNumber(+pricing.vat)} EGP
                    </span>
                  </div>
                </div>

                <div className="mt-[15px]">
                  <span className=" font-semibold">Total</span>
                  <br />
                  <span className="text-[36px] " style={{ fontFamily: "gilroy-bold" }}>
                    {prettyNumber(+pricing.totalDiscounted)} EGP
                  </span>
                </div>
              </div>
            )}

            <div className="flex mt-4 w-full justify-end gap-10 ">
              <Button
                secondary={true}
                invert={true}
                onClick={() => {
                  close();
                  fetchAppointmentsDataAfterDelete();
                }}
                // onClick={() => setOpened(false)}
              >
                Cancel
              </Button>
              <Button loading={submitLoading} secondary={true} type="submit">
                Confirm Booking
              </Button>
            </div>
          </form>
        </div>
      ) : (
        // Step2
        <CalendarFormStep2
          subTotal={pricing.subTotal}
          vat={pricing.vat}
          total={pricing.totalDiscounted}
          discount={pricing.discountAmount}
          promoCodeAmount={pricing.promoCodeAmount}
          responseData={responseData}
          selectedTimeSlotData={selectedTimeSlotData}
          open={open}
          close={close}
          wht={pricing?.whtAmount}
          facilities={facilities}
          paymentMethods={paymentMethods}
          fetchAppointmentsData={fetchAppointmentsDataAfterDelete}
        />
      )}
    </div>
  );
};

export default CalendarForm;
