import "./App.css";
import { Router } from "./routes/Routes";
import "./assets/fonts/Gilroy/Gilroy-Light.otf";
import ToasterProvider from "./providers/ToasterProvider";
import { AuthProvider } from "react-auth-kit";
import { SelectedLocationProvider } from "./Context/LocationProvider";
import VasCartProvider from "./Context/VasCart";
import moment from "moment-timezone";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
moment.tz.setDefault("Africa/Cairo");

const queryClient = new QueryClient({
  // disable query caching
  defaultOptions: {
    queries: {
      gcTime: 0,
    },
  },
});
function App() {
  return (
    <AuthProvider
      authType={"localstorage"}
      authName={"_auth"}
      // cookieDomain={window.location.hostname}
      // cookieSecure={window.location.protocol === "https:"}
    >
      <QueryClientProvider client={queryClient}>
        <VasCartProvider>
          <SelectedLocationProvider>
            <ToasterProvider />
            <Router />
          </SelectedLocationProvider>
        </VasCartProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
