import SelectCurrency from "../../../components/Currencies/SelectCurrency";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../components/Button/Button";
import ModalComponent from "../../../components/Modal/Modal2";
import { http } from "../../../utils/httpCommon";
import { toast } from "react-hot-toast";
import { TextInput } from "../../../components/TextInput/TextInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useFetchData from "../../../hooks/FetchData";
import { FormControl, InputLabel, MenuItem, Select, Switch } from "@mui/material";
import { Loader } from "tabler-icons-react";
import AsyncSelect from "react-select/async";
import { useNavigate } from "react-router-dom";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { prettyNumber } from "../../../lib/prettyNumber";
import CalculatedCurrencyAmount from "../../../components/Currencies/CalculatedCurrencyAmount";

const NewAddCreditModal = ({ opened, setOpened, response, refetch }) => {
  const [isClient, setIsClient] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState("");
  const { response: paymentMethods, loading: LoadingPaymentsMethods } = useFetchData(
    `financials/${selectedLocation || "none"}/payment-method-accounts`,
  );

  const {
    response: locations,
    fetchData: fetchAllLocations,
    loading: loadingLocations,
  } = useFetchData("/location/me");

  useEffect(() => {
    fetchAllLocations();
  }, []);

  const schema = yup
    .object({
      accountId: yup
        .object()
        .nullable()
        .required(isClient ? "Client is required" : "Company is required"),
      amount: yup
        .number()
        .required("Amount is required")
        .positive("Amount must be a positive number"),
      locationId: yup.string().typeError("Location is required").required("Location is required"),
      paymentMethodId: yup
        .string()
        .typeError("Payment method paid is required")
        .required("payment method is required"),
      currency: yup.string().required("Currency is required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      locationId: selectedLocation,
      amount: response?.wallet?.credit,
    },
  });
  const client = watch("accountId")?.value;

  const [disableButton, setDisableButton] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("EGP");

  useEffect(() => {
    if (client?.preferredCurrency?._id) {
      setValue("currency", client?.preferredCurrency?._id);
    } else {
      setValue("currency", "");
    }
  }, [client?._id]);

  const amount = watch("amount") || 0;

  const onSubmit = async (data) => {
    setDisableButton(true);
    try {
      await http.post(
        `/credit`,
        JSON.stringify({
          amount: data.amount,
          paymentMethodId: data.paymentMethodId,
          locationId: data?.locationId,
          accountId: data?.accountId?.value?._id,
          creditType: isClient ? "client" : "company",
          currencyId: data.currency,
        }),
      );
      toast.success("Credit Added Successfully");
      refetch();
      reset();
      setOpened(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong",
      );
    } finally {
      setDisableButton(false);
    }
  };

  const handleOnClose = () => {
    setOpened(false);
  };

  //client selection
  const loadOptions = async (inputText, callback) => {
    let res = null;
    if (inputText.length > 0) {
      let response = await http.get(`/client/find?q=${inputText}`);
      res = response?.data?.data;
      callback(
        res.map((i) => ({
          label: (
            <div className="flex items-center gap-2">
              {i?.profilePicture && (
                <img
                  className="w-[40px] h-[40px] rounded-full  object-cover"
                  src={i?.profilePicture}
                  alt={i.name}
                />
              )}{" "}
              {i.name}
            </div>
          ),
          value: i,
          ...i,
        })),
      );
    }
  };

  const loadOptionsCompanies = async (inputText, callback) => {
    let res = null;
    if (inputText.length > 0) {
      var data = JSON.stringify({
        textField: inputText,
        page: 1,
        limit: 10000,
      });
      let response = await http.post("/company/all/", data);
      res = response?.data?.data?.data;
      callback(
        res.map((i) => ({
          label: (
            <div className="flex items-center gap-3" key={i._id}>
              {i?.logo && (
                <img
                  src={i?.logo}
                  alt={i.name}
                  className="w-[40px] h-[40px] rounded-full object-cover"
                />
              )}
              {i.name}
            </div>
          ),
          value: i,
        })),
      );
    }
  };

  const navigator = useNavigate();
  const CustomNoOptionsMessage = ({ inputValue, onResetClick }) => (
    <div className="p-2">
      No results found{" "}
      <button
        className=" text-[18px]  text-[#34B2C0]  hover:opacity-95"
        style={{ fontFamily: "gilroy-bold" }}
        onClick={() => navigator(`/community/clients/new-client`)}
      >
        Add new client
      </button>
    </div>
  );

  return (
    <ModalComponent size={"xl"} opened={opened} setOpened={setOpened} onClose={handleOnClose}>
      {loadingLocations ? (
        <Loader />
      ) : (
        <div className="flex justify-center flex-col items-center gap-[25px] p-8 ">
          <div className=" text-[25px] font-bold ">Add Credit Balance </div>
          <div className="flex items-center gap-4">
            <span>Client</span>
            <Switch checked={!isClient} onChange={() => setIsClient(!isClient)} />
            <span>Company</span>
          </div>
          <form className="w-full  " onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="mb-2 text-[20px]">{isClient ? "Client" : "Company"}</div>
              <div>
                <Controller
                  control={control}
                  name="accountId"
                  render={({ field }) => (
                    <AsyncSelect
                      components={{
                        NoOptionsMessage: ({ inputValue }) => (
                          <CustomNoOptionsMessage inputValue={inputValue} onResetClick={() => {}} />
                        ),
                      }}
                      isClearable
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: "56px",
                          borderRadius: "8px",
                          borderColor: "black",
                          "&:hover": {},
                        }),
                        option: (styles) => ({
                          ...styles,
                          zIndex: "999 !important",
                          backgroundColor: "white !important",
                        }),
                      }}
                      {...field}
                      placeholder={isClient ? "Search for Client" : "Search for Company"}
                      loadOptions={isClient ? loadOptions : loadOptionsCompanies}
                    />
                  )}
                />
              </div>
              <p className="text-red-500">{errors.accountId?.message}</p>
            </div>
            <div className="mt-6">
              <FormControl fullWidth sx={{ border: "none !important" }}>
                <InputLabel>Location </InputLabel>
                <Controller
                  control={control}
                  name="locationId"
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <Select
                      sx={{
                        color: "black",
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#EFEFEF",
                        },
                      }}
                      onBlur={onBlur}
                      onChange={(value) => {
                        onChange(value.target.value);
                        setSelectedLocation(value.target.value);
                      }}
                      variant="outlined"
                      labelId="Location"
                      id="Location"
                      value={value}
                      label="Location"
                      inputRef={ref}
                      defaultValue=""
                    >
                      <MenuItem className="w-full" value="">
                        <em>None</em>
                      </MenuItem>
                      {locations?.map((l) => (
                        <MenuItem key={l?._id} value={l?._id}>
                          {l?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <p className="text-red-500">{errors.locationId?.message}</p>
            </div>

            <div className="mt-6">
              {!LoadingPaymentsMethods && (
                <div className="mt-4 mb-2 grid grid-cols-3 gap-2">
                  <div className="col-span-2">
                    <FormControl fullWidth sx={{ border: "none !important" }}>
                      <InputLabel>Payment Method</InputLabel>
                      <Controller
                        control={control}
                        name="paymentMethodId"
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                          <Select
                            sx={{
                              color: "black",
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#EFEFEF",
                              },
                            }}
                            onBlur={onBlur}
                            onChange={onChange}
                            variant="outlined"
                            labelId="Payment Method-label"
                            id="Payment Method"
                            value={value}
                            label="Payment Method"
                            inputRef={ref}
                            defaultValue=""
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {paymentMethods?.map((l) => {
                              return (
                                l?.name.toLowerCase() != "receivable" && (
                                  <MenuItem key={l?.qboId} value={l?.qboId}>
                                    {l?.name}
                                  </MenuItem>
                                )
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                    <p className="text-red-500">{errors.paymentMethodId?.message}</p>
                  </div>
                  <div className="col-span-1">
                    <FormControl fullWidth sx={{ border: "none !important" }}>
                      <InputLabel>Currency</InputLabel>
                      <Controller
                        control={control}
                        defaultValue={client?.preferredCurrency?._id}
                        name="currency"
                        render={({ field: { onChange, value, defaultValue } }) => (
                          <SelectCurrency
                            value={value}
                            onChange={onChange}
                            defaultValue={defaultValue}
                            preferredCurrency={client?.preferredCurrency?._id}
                          />
                        )}
                      />
                    </FormControl>

                    <p className="text-red-500">{errors.currency?.message}</p>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-6">
              <TextInput {...register("amount")} label={`Amount `} />
              <p className="text-red-500 text-[16px]">{errors.amount?.message}</p>
            </div>

            <div className="flex gap-[40px] mt-8">
              <Button
                disabled={disableButton}
                onClick={handleOnClose}
                secondary={true}
                invert={true}
                type="button"
              >
                Cancel
              </Button>
              <Button disabled={disableButton} secondary type="submit">
                {disableButton ? <LoadingCircle /> : "Confirm"}
              </Button>
            </div>
          </form>
        </div>
      )}
    </ModalComponent>
  );
};

export default NewAddCreditModal;
