import { http } from "../../../../utils/httpCommon";
import { useEffect, useState } from "react";

const useFetchCurrencies = (search, page = 1, limit) => {
  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await http.get(
          `/currencies/?page=${page}${limit ? `&limit=${limit}` : ""}${search ? `&search=${search}` : ""}`,
        );
        setCurrencies(response?.data?.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchCurrencies();
  }, [page, limit, search]);

  return { currencies, loading, error };
};

export default useFetchCurrencies;
