import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import useFetchCurrencies from "../../Pages/Operations/NewCalendar/Hooks/useFetchCurrencies";
import { useState } from "react";
import ModalComponent from "../../components/Modal/Modal2";
import { Button } from "../Button/Button";

export default function SelectCurrency({ value, onChange, defaultValue, preferredCurrency }) {
  const [event, setEvent] = useState("");
  const { currencies } = useFetchCurrencies();

  const onChangeMiddleware = (e) => {
    if (preferredCurrency && e.target.value !== preferredCurrency) {
      setEvent(e);
    } else {
      onChange(e);
    }
  };

  return (
    <>
      {event && (
        <ConfirmationModal
          open={!!event}
          onClose={() => setEvent("")}
          onConfirm={() => {
            onChange(event);
            setEvent("");
          }}
          title="Change Currency"
          description="You are about to change the preferred currency. Are you sure you want to proceed?"
        />
      )}
      <Select
        onChange={onChangeMiddleware}
        value={value}
        defaultValue={defaultValue}
        variant="outlined"
        labelId="currency-label"
        id="Currency"
        label="Currency"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {currencies?.map((l) => {
          return (
            <MenuItem key={l?._id} value={l?._id}>
              {l?.symbol} {l?.name}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
}

const ConfirmationModal = ({ open, onClose, onConfirm, title, description }) => {
  return (
    <ModalComponent size={"lg"} opened={open} onClose={onClose}>
      <div>
        <p className="text-lg font-bold text-gray-900 mt-2">{description}</p>
        <div className="mt-4 mb-6 flex justify-end gap-10">
          <Button onClick={onClose} secondary={true} invert={true}>
            Cancel
          </Button>
          <Button type="submit" secondary={true} onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};
