import React, { useState } from "react";
import Table from "../../components/DataGrid//Table";
import { useApiQuery } from "../../hooks/useApiQuery";

const PaginatedPage = ({
  columns = [],
  queryParams = {},
  title = "",
  baseUrl,
  searchKey = "search",
  responseParentKey = "data",
  totalCountResponseKey = "length",
  queryOptions = {},
  ...props
}) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = React.useState("");

  const { isPending: isLoading, data } = useApiQuery({
    baseUrl,
    queryParams: {
      page: page,
      limit: limit,
      [searchKey]: searchQuery,
      ...queryParams,
    },
    options: queryOptions,
  });
  const items = data?.[responseParentKey] || [];
  const total = data?.[totalCountResponseKey] || 0;

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const columnsKeys = columns.map((column) => column?.name) || [];
  return (
    <div>
      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={total || 0}
        isLoading={isLoading}
        data={items}
        page={page - 1}
        pageSize={limit}
        onPageChange={(old) => setPage(old + 1)}
        onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
        columns={columns}
        keys={columnsKeys}
        title={title}
        {...props}
      />
    </div>
  );
};

export default PaginatedPage;
