import React, { useEffect, useState } from "react";
import { Layout } from "../../../../components/Layout/Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import profileImg from "../../../../assets/profile.png";
import useFetchData from "../../../../hooks/FetchData";
// import whiteTrash from "../../../../assets/icons/whiteTrash.png";
import whiteEdit from "../../../../assets/icons/whiteEdit.png";
import archiveImg from "../../../../assets/icons/archive.png";
import phoneIcon from "../../../../assets/icons/call.png";
import atIcon from "../../../../assets/icons/@.png";
import CompanyIcon from "../../../../assets/icons/company.png";
import plusIcon from "../../../../assets/icons/whitePlus.png";
import MqrCoin from "../../../../assets/icons/mqrCoin.png";
import LoadingCircle from "../../../../components/LoadingCircle/LoadingCircle";
import ArchiveClient from "./ArchiveClient";
import SendClientNotification from "./SendClientNotification";
import DeleteClient from "./DeleteClient";
import Trash from "../../../../assets/icons/DeleteIcon.png";
import { useAuthUser } from "react-auth-kit";
import EditCoinBank from "./EditCoinBank";
import { Drawer } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import AddBookingForm from "./AddBookingForm";
import EditCreditBalance from "./EditCreditBalnace";
import WalletIcon from "../../../../assets/icons/wallet-icon.png";
import ClientSubscriptions from "./ClientSubscriptions";
import { CurrencyCent } from "tabler-icons-react";

const Client = () => {
  const params = useParams();
  const navigator = useNavigate();
  const { response, loading, refetch } = useFetchData(`/client/${params.id}`);
  const auth = useAuthUser();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    // getUserData();
  }, []);
  const taps = [
    { name: "Personal info", index: 0 },
    { name: "Company", index: 1 },
    { name: "Wallet", index: 2 },
    { name: "Activity Log", index: 3 },
    { name: "Subscriptions", index: 4 },
  ];

  const handleActiveLink = (index) => {
    setActiveTab(index);
  };

  const [openCheckInModal, setOpenCheckInModal] = useState(false);

  const handleCheckInModal = () => {
    setOpenCheckInModal(true);
  };

  //handle Archive
  const [openArchiveClientModal, setOpenArchiveClientModal] = useState(false);
  const [openDeleteClientModal, setOpenDeleteClientModal] = useState(false);

  const [openSendNotificationModal, setOpenSendNotificationModal] = useState(false);
  const [openEditClientCoin, setOpenEditClientCoin] = useState(false);
  const [openEditClientCredit, setOpenEditClientCredit] = useState(false);

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Layout>
        {!loading ? (
          <>
            <Drawer
              opened={opened}
              onClose={() => {
                close();
              }}
              position="right"
              size={"lg"}
              overlayProps={{ opacity: 0.6, blur: 5 }}
            >
              <AddBookingForm open={open} close={close} client={response} />
            </Drawer>
            {/* <NewCheckIn data={response} opened={openCheckInModal} setOpened={setOpenCheckInModal} /> */}
            <ArchiveClient
              opened={openArchiveClientModal}
              setOpened={setOpenArchiveClientModal}
              name={response?.name}
              id={response?._id}
            />
            <EditCreditBalance
              opened={openEditClientCredit}
              setOpened={setOpenEditClientCredit}
              response={response}
              refetch={refetch}
            />
            <EditCoinBank
              opened={openEditClientCoin}
              setOpened={setOpenEditClientCoin}
              response={response}
              refetch={refetch}
            />
            <DeleteClient
              opened={openDeleteClientModal}
              setOpened={setOpenDeleteClientModal}
              name={response?.name}
              id={response?._id}
            />
            <SendClientNotification
              opened={openSendNotificationModal}
              setOpened={setOpenSendNotificationModal}
              res={response}
            />
            <div className="bg-white m-2 gap-[50px] rounded-[20px] flex p-4   w-[90%] min-h-[500px] ">
              <div className="flex flex-col h-full  ">
                <img
                  className="w-full h-[300px]  object-cover rounded-[20px] "
                  src={response?.profilePicture ? response?.profilePicture : profileImg}
                  alt={response?.name}
                />
                <div className="mt-4 text-[25px] font-bold  ">{response?.name}</div>
              </div>
              <div className="flex flex-col h-full w-full   ">
                {auth().role !== "Land Lord" && (
                  <div className="flex justify-between items-center w-full">
                    <div className="flex gap-[25px] ">
                      <button
                        onClick={() => navigator(`/community/edit-client/${response?._id}`)}
                        className="text-[16px] group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                      >
                        <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                          <img className=" w-[18px] " src={whiteEdit} alt="edit" />
                        </div>
                        Edit Profile
                      </button>

                      {auth().role === "Experience Officer" ||
                      auth().role === "Branch Manager" ||
                      auth().role === "Area Manager" ? null : (
                        <>
                          <button
                            onClick={() => setOpenArchiveClientModal(true)}
                            className="text-[16px] group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                          >
                            <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                              <img className=" w-[18px] " src={archiveImg} alt="Archive" />
                            </div>
                            Archive
                          </button>
                          <button
                            onClick={() => setOpenDeleteClientModal(true)}
                            className="text-[16px] group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                          >
                            <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                              <img className=" w-[18px] " src={Trash} alt="Archive" />
                            </div>
                            Delete
                          </button>
                        </>
                      )}
                      {/* <button
                      onClick={() => setOpenSendNotificationModal(true)}
                      className="text-[16px] group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                      >
                      <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                        <img
                          className=" w-[18px] "
                          src={notificationIcon}
                          alt="notificationIcon"
                        />
                      </div>
                      Send Notifications
                    </button> */}
                    </div>
                    <button
                      onClick={open}
                      className=" bg-[#32B3BE] font-bold text-white text-[18px] px-4 py-1 rounded-md hover:bg-[#32b2bebd] transition-all"
                    >
                      Add Booking
                    </button>
                    {/* <Button onClick={() => handleCheckInModal()} secondary={true}>
                    Check In
                  </Button> */}
                  </div>
                )}
                {/* Taps */}
                <div className="mt-2 mb-0 flex w-full max-w-[500px]   text-[16px] font-medium     ">
                  {taps.map((t, index) => (
                    <button
                      onClick={() => handleActiveLink(index)}
                      className={` ${
                        activeTab === t.index
                          ? "text-black border-b-[#32B3BE] border-b-[6px]"
                          : " text-[#8E8E8E]  border-b-[#EFEFEF]  border-b-[6px]"
                      } flex   font-semibold  w-full transition-all duration-150 
                  underline-offset-8 
                  `}
                      key={index}
                    >
                      <div className="px-2 w-[115px]">{t.name}</div>
                    </button>
                  ))}
                </div>

                <div>
                  {activeTab === 0 ? (
                    // Personal info
                    <div className="flex flex-col my-6">
                      <div className="text-[#8E8E8E] font-semibold ">
                        {response?.gender
                          ? response?.gender?.charAt(0)?.toUpperCase() + response?.gender?.slice(1)
                          : null}
                      </div>
                      {/* <div className="mt-2 font-semibold">Travel & Tourism</div> */}
                      <div className="flex items-start my-2 gap-[10px]">
                        <img className="w-[15px] mt-1" src={phoneIcon} alt={response?.phone} />
                        <div className="flex flex-col">
                          <span className="font-semibold">
                            {response?.phone ? response?.phone : "0102033033"}
                          </span>{" "}
                          {response?.phoneVerified ? (
                            <span className="text-[#00AD3B] font-medium">*Status Verified </span>
                          ) : (
                            <span className="text-[#B3B3B3] text-[16px] font-medium">
                              *Pending Verification
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex items-start my-2 gap-[10px]">
                        <img className="w-[15px] mt-1" src={atIcon} alt={response?.email} />
                        <div className="flex flex-col text-[16px]">
                          <span className="font-semibold">{response?.email}</span>
                          {response?.emailVerified ? (
                            <span className="text-[#00AD3B] font-medium">*Status Verified </span>
                          ) : (
                            <span className="text-[#B3B3B3] text-[16px] font-medium">
                              *Pending Verification
                            </span>
                          )}
                        </div>
                      </div>
                      {response?.preferredCurrency && (
                        <div className="flex items-start my-2 gap-[10px]">
                          <CurrencyCent className="w-[15px] mt-1" />
                          <div className="flex flex-col text-[16px]">
                            <span className="text-[#8E8E8E] font-semibold">Preferred Currency</span>
                            <span className="font-semibold">
                              {response?.preferredCurrency?.name} -{" "}
                              {response?.preferredCurrency?.code}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : activeTab === 1 ? (
                    // Company
                    <div className="flex flex-col my-6">
                      {response?.company ? (
                        <>
                          <div>
                            <div className="flex gap-[20px] items-center">
                              <span
                                className="text-[20px] font-bold "
                                // style={{fontFamily:"gilroy-bold"}}
                              >
                                Companies
                              </span>
                              <button
                                onClick={() => navigator(`/community/companies/new-company`)}
                                className="group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                              >
                                <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                                  <img className=" w-[18px] " src={plusIcon} alt="Add" />
                                </div>
                                Add Company
                              </button>
                            </div>

                            <div className=" border-[1px] border-[#79797933] w-fit my-2 p-5 rounded-[10px] ">
                              <div className="text-[25px] " style={{ fontFamily: "gilroy-bold" }}>
                                {response?.company?.name}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                          <img className="w-[146px]" src={CompanyIcon} alt="no company" />
                          <div className="text-[#8E8E8E] my-4  text-[20px]">
                            Not Part of any company yet
                          </div>
                          <Link
                            to={`/community/companies/new-company`}
                            className="group text-[#686868] flex items-center text-[20px] hover:text-[#32B3BE] transition-all "
                          >
                            <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                              <img className=" w-[18px] " src={plusIcon} alt="Add" />
                            </div>
                            Add Company
                          </Link>
                        </div>
                      )}
                    </div>
                  ) : activeTab === 2 ? (
                    <div className="mt-6 flex flex-col 2xl:flex-row gap-4  justify-start items-start pr-32  2xl:justify-between 2xl:items-center ">
                      <div className="">
                        <div
                          className="text-[18px] gap-4 flex items-center "
                          style={{ fontFamily: "gilroy-bold" }}
                        >
                          Coin Bank
                          <button
                            onClick={() => setOpenEditClientCoin(true)}
                            className=" px-2 py-1 bg-black rounded-[10px] text-white  font-medium hover:bg-gray-900 hover:scale-105 transition-all"
                          >
                            Edit Coin Balance
                          </button>
                        </div>
                        <div className=" border-[1px] border-[#79797933] w-fit my-3 p-5 rounded-[10px] ">
                          <span className=" text-[#797979] font-semibold">
                            Current Coin Balance
                          </span>
                          <div className="flex gap-[10px] items-center mt-2">
                            <img className="w-[40px] " src={MqrCoin} alt="Mqr Coin" />
                            <span className="text-[40px]" style={{ fontFamily: "gilroy-bold" }}>
                              {response?.wallet?.balance}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          className="text-[18px] gap-4 flex items-center "
                          style={{ fontFamily: "gilroy-bold" }}
                        >
                          Credit
                          {/*
     <button
                            onClick={() => setOpenEditClientCredit(true)}
                            className=" px-2 py-1 bg-black rounded-[10px] text-white  font-medium hover:bg-gray-900 hover:scale-105 transition-all"
                          >
                            Edit Credit Balance
                          </button>
                          */}
                        </div>
                        <div className=" border-[1px] border-[#79797933] w-fit my-3 p-5 rounded-[10px] ">
                          <span className=" text-[#797979] font-semibold">
                            Current Credit Balance
                          </span>
                          <div className="flex gap-[10px] items-center mt-2">
                            <img className="w-[40px] " src={WalletIcon} alt="Mqr Coin" />
                            <span className="text-[40px]" style={{ fontFamily: "gilroy-bold" }}>
                              {response?.wallet?.credit}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : activeTab === 4 ? (
                    <ClientSubscriptions clientId={response?._id} />
                  ) : (
                    <div className="mt-6">Activity Log</div>
                  )}
                </div>
              </div>
            </div>{" "}
          </>
        ) : (
          <LoadingCircle />
        )}
      </Layout>
    </>
  );
};

export default Client;
