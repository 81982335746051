import { BrowserRouter, Routes, Route } from "react-router-dom";
import Community from "../Pages/Community/Community";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Finance from "../Pages/Finance/Finance";
import Operations from "../Pages/Operations/Operations";
import Settings from "../Pages/Settings/Settings";
import Inventory from "../Pages/Inventory/Inventory";
import Locations from "../Pages/Inventory/Locations/Locations";
import Login from "../Pages/Login/Login";
// import { UserContextProvider } from "../Context/Auth";
import ResetPassword from "../Pages/Login/ResetPassword";
import SetPassword from "../Pages/Login/SetPassword";
import NewLocation from "../Pages/Inventory/Locations/NewLocation";
import Location from "../Pages/Inventory/Locations/Location/Location";
import Facility from "../Pages/Inventory/Facilities/Facility";
import NewFacility from "../Pages/Inventory/Facilities/NewFacility";
import EditFacility from "../Pages/Inventory/Facilities/EditFacility";
import EditLocation from "../Pages/Inventory/Locations/EditLocation";
import Companies from "../Pages/Community/Companies/Companies";
import Requests from "../Pages/Community/Requests/Requests";
import Client from "../Pages/Community/Clients/Client/Client";
import NewAdmin from "../Pages/Settings/Admins/NewAdmin";
import Admin from "../Pages/Settings/Admins/Admin/Admin";
import EditAdmin from "../Pages/Settings/Admins/EditAdmin";
import Company from "../Pages/Community/Companies/Company/Company";
import NewCompany from "../Pages/Community/Companies/NewCompnay";
import EditCompany from "../Pages/Community/Companies/EditCompany";
import NewClient from "../Pages/Community/Clients/NewClient";
import EditClient from "../Pages/Community/Clients/EditClient";
import RentDetails from "../Pages/Operations/Rent/RentDetails/RentDetails";
import { RequireAuth } from "react-auth-kit";
import AdminsTable from "../Pages/Settings/Admins/AdminsTable";
import AmenitiesTable from "../Pages/Inventory/Amenities/AmenitiesTable";
import FacilitiesTable from "../Pages/Inventory/Facilities/FacilitiesTable";
import ClientsTable from "../Pages/Community/Clients/ClientTable";
import NewVas from "../Pages/Operations/Vas/NewVas";
import VasPage from "./../Pages/Operations/Vas/VasPage/Vas";
import EditVas from "../Pages/Operations/Vas/VasPage/EditVas";
import NewPromoCode from "../Pages/Finance/PromoCodes/NewPromoCode";
import EditPromoCode from "../Pages/Finance/PromoCodes/EditPromoCode";
import PromoCode from "../Pages/Finance/PromoCodes/PromoCode";
import NewPackage from "../Pages/Operations/Packages/NewPackage";
import EditPackage from "../Pages/Operations/Packages/EditPackage";
import Package from "../Pages/Operations/Packages/Package";
import Tutorials from "../Pages/tutorials/tutorials";
import Currencies from "../Pages/Currencies/Currencies";
import Subscription from "../Pages/Finance/Subscriptions/Subscription";
// const ProtectedRoute = () => {
//   let token = localStorage.getItem("token");
//   return token ? <Outlet /> : <Navigate to="/login" />;
// };

export function Router() {
  return (
    <BrowserRouter>
      {/* <UserContextProvider> */}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/resetPassword/:token" element={<ResetPassword />} />
        <Route path="/setPassword/:token" element={<SetPassword />} />
        <Route
          path={"/"}
          element={
            <RequireAuth loginPath={"/login"}>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/operations"
          element={
            <RequireAuth loginPath={"/login"}>
              <Operations />
            </RequireAuth>
          }
        />
        <Route
          path="/operations/bookings"
          element={
            <RequireAuth loginPath={"/login"}>
              <Operations />
            </RequireAuth>
          }
        />
        <Route
          path="/operations/offices"
          element={
            <RequireAuth loginPath={"/login"}>
              <Operations />
            </RequireAuth>
          }
        />
        <Route
          path="/operations/day-passes"
          element={
            <RequireAuth loginPath={"/login"}>
              <Operations />
            </RequireAuth>
          }
        />
        <Route
          path="/operations/vas"
          element={
            <RequireAuth loginPath={"/login"}>
              <Operations />
            </RequireAuth>
          }
        />
        <Route
          path="/operations/rent-contract/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <RentDetails />
            </RequireAuth>
          }
        />

        {/* VaS */}

        <Route
          path="/operations/vas/add-new-vas"
          element={
            <RequireAuth loginPath={"/login"}>
              <NewVas />
            </RequireAuth>
          }
        />

        <Route
          path="/operations/vas/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <VasPage />
            </RequireAuth>
          }
        />
        <Route
          path="/operations/vas/edit-vas/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <EditVas />
            </RequireAuth>
          }
        />

        {/* Finance         */}
        <Route
          path="/finance"
          element={
            <RequireAuth loginPath={"/login"}>
              <Finance />
            </RequireAuth>
          }
        />

        <Route
          path="/finance/transactions"
          element={
            <RequireAuth loginPath={"/login"}>
              <Finance />
            </RequireAuth>
          }
        />

        <Route
          path="/finance/invoices"
          element={
            <RequireAuth loginPath={"/login"}>
              <Finance />
            </RequireAuth>
          }
        />

        <Route
          path="/finance/reconsiliations"
          element={
            <RequireAuth loginPath={"/login"}>
              <Finance />
            </RequireAuth>
          }
        />

        <Route
          path="/finance/total"
          element={
            <RequireAuth loginPath={"/login"}>
              <Finance />
            </RequireAuth>
          }
        />

        <Route
          path="/finance/promo-codes"
          element={
            <RequireAuth loginPath={"/login"}>
              <Finance />
            </RequireAuth>
          }
        />

        <Route
          path="/finance/promo-code/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <PromoCode />
            </RequireAuth>
          }
        />

        <Route
          path="/finance/promo-codes/new"
          element={
            <RequireAuth loginPath={"/login"}>
              <NewPromoCode />
            </RequireAuth>
          }
        />

        <Route
          path="/finance/promo-code/edit/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <EditPromoCode />
            </RequireAuth>
          }
        />

        <Route
          path="/finance/receivables"
          element={
            <RequireAuth loginPath={"/login"}>
              <Finance />
            </RequireAuth>
          }
        />

        <Route
          path="/finance/subscriptions"
          element={
            <RequireAuth loginPath={"/login"}>
              <Finance />
            </RequireAuth>
          }
        />
        <Route
          path="/finance/currency-transactions"
          element={
            <RequireAuth loginPath={"/login"}>
              <Finance />
            </RequireAuth>
          }
        />
        <Route
          path="/finance/subscriptions/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <Subscription />
            </RequireAuth>
          }
        />

        {/* Community */}
        <Route
          path="/community"
          element={
            <RequireAuth loginPath={"/login"}>
              <Community />
            </RequireAuth>
          }
        />

        <Route
          path="/inventory"
          element={
            <RequireAuth loginPath={"/login"}>
              <Inventory />
            </RequireAuth>
          }
        />
        <Route
          path="/inventory/locations/add-location"
          element={
            <RequireAuth loginPath={"/login"}>
              <NewLocation />
            </RequireAuth>
          }
        />

        <Route
          path="/inventory/Locations"
          element={
            <RequireAuth loginPath={"/login"}>
              <Locations />
            </RequireAuth>
          }
        />
        <Route
          path="/inventory/Amenities"
          element={
            <RequireAuth loginPath={"/login"}>
              <AmenitiesTable />
            </RequireAuth>
          }
        />

        <Route
          path="/inventory/location/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <Location />
            </RequireAuth>
          }
        />
        <Route
          path="/inventory/edit-location/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <EditLocation />
            </RequireAuth>
          }
        />

        <Route
          path="/inventory/facilities"
          element={
            <RequireAuth loginPath={"/login"}>
              <FacilitiesTable />
            </RequireAuth>
          }
        />
        <Route
          path="/inventory/add-facility"
          element={
            <RequireAuth loginPath={"/login"}>
              <NewFacility />
            </RequireAuth>
          }
        />
        <Route
          path="/inventory/edit-facility/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <EditFacility />
            </RequireAuth>
          }
        />
        <Route
          path="/inventory/facility/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <Facility />
            </RequireAuth>
          }
        />

        <Route
          path="/community/clients"
          element={
            <RequireAuth loginPath={"/login"}>
              <ClientsTable />
            </RequireAuth>
          }
        />
        <Route
          path="/community/client/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <Client />
            </RequireAuth>
          }
        />
        <Route
          path="/community/clients/new-client"
          element={
            <RequireAuth loginPath={"/login"}>
              <NewClient />
            </RequireAuth>
          }
        />
        <Route
          path="/community/edit-client/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <EditClient />
            </RequireAuth>
          }
        />

        <Route
          path="/community/Companies"
          element={
            <RequireAuth loginPath={"/login"}>
              <Companies />
            </RequireAuth>
          }
        />
        <Route
          path="/community/companies/new-company"
          element={
            <RequireAuth loginPath={"/login"}>
              <NewCompany />
            </RequireAuth>
          }
        />
        <Route
          path="/community/edit-company/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <EditCompany />
            </RequireAuth>
          }
        />
        <Route
          path="/community/company/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <Company />
            </RequireAuth>
          }
        />

        <Route
          path="/community/Requests"
          element={
            <RequireAuth loginPath={"/login"}>
              <Requests />
            </RequireAuth>
          }
        />

        <Route
          path="/settings/admins"
          element={
            <RequireAuth loginPath={"/login"}>
              <AdminsTable />
            </RequireAuth>
          }
        />
        <Route
          path="/settings/admins/add-admin"
          element={
            <RequireAuth loginPath={"/login"}>
              <NewAdmin />
            </RequireAuth>
          }
        />
        <Route
          path="/settings/edit-admin/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <EditAdmin />
            </RequireAuth>
          }
        />
        <Route
          path="/settings/admin/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <Admin />
            </RequireAuth>
          }
        />

        <Route
          path="/settings"
          element={
            <RequireAuth loginPath={"/login"}>
              <Settings />
            </RequireAuth>
          }
        />

        {/* Packages */}

        <Route
          path="/operations/packages"
          element={
            <RequireAuth loginPath={"/login"}>
              <Operations />
            </RequireAuth>
          }
        />

        <Route
          path="/operations/add-package"
          element={
            <RequireAuth loginPath={"/login"}>
              <NewPackage />
            </RequireAuth>
          }
        />

        <Route
          path="/operations/edit-package/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <EditPackage />
            </RequireAuth>
          }
        />
        <Route
          path="/operations/package/:id"
          element={
            <RequireAuth loginPath={"/login"}>
              <Package />
            </RequireAuth>
          }
        />
        <Route
          path="/inventory/packages"
          element={
            <RequireAuth loginPath={"/login"}>
              <Inventory />
            </RequireAuth>
          }
        />

        <Route
          path="/"
          element={
            <RequireAuth loginPath={"/login"}>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/experience"
          element={
            <RequireAuth loginPath={"/login"}>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/community"
          element={
            <RequireAuth loginPath={"/login"}>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/finance"
          element={
            <RequireAuth loginPath={"/login"}>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard/revenues"
          element={
            <RequireAuth loginPath={"/login"}>
              <Dashboard />
            </RequireAuth>
          }
        />

        <Route
          path="/operations/credit"
          element={
            <RequireAuth loginPath={"/login"}>
              <Operations />
            </RequireAuth>
          }
        />

        <Route
          path="/tutorials"
          element={
            <RequireAuth loginPath={"/login"}>
              <Tutorials />
            </RequireAuth>
          }
        />

        <Route
          path="/currencies"
          element={
            <RequireAuth loginPath={"/login"}>
              <Currencies />
            </RequireAuth>
          }
        />
      </Routes>

      {/* </UserContextProvider> */}
    </BrowserRouter>
  );
}
