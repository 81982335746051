import { useQuery } from "@tanstack/react-query";
import { http } from "../utils/httpCommon";
import { encodedURIUrl } from "../utils/encodeUrlParams";

/**
 *
 * @param {Object} param0
 * @param {string} param0.baseUrl - The base URL for the API
 * @param {Object} param0.queryParams - The query parameters to be sent to the API
 * @param {string} param0.queryKey - The key to be used for the query
 * @param {Object} param0.options - The options to be passed to the useQuery hook
 * @returns {Object} - The response object from the useQuery hook
 * @description - A custom hook to be used for querying APIs
 * @example - useApiQuery({ baseUrl: "/transactions-currencies", queryParams: { location: selectedLocation }, queryKey: "transactions-currencies", options: {}, responseItemKey: "data" });
 * @returns {Object} - The response object from the useQuery hook
 * @description - A custom hook to be used for querying APIs
 * @example - useApiQuery({ baseUrl: "/transactions-currencies", queryParams: { location: selectedLocation }, queryKey: "transactions-currencies", options: {}, responseItemKey: "data" });
 *
 */
export function useApiQuery({ baseUrl, queryParams, queryKey, options }) {
  return useQuery({
    queryKey: [queryKey, queryParams],
    queryFn: async () => {
      const url = encodedURIUrl(baseUrl, queryParams);
      const response = await http.get(url);
      return response.data;
    },
    ...options,
  });
}
