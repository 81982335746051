export function encodedURIUrl(url, queryParams) {
  const flattenObject = (obj, prefix = "") => {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object" && value !== null && !Array.isArray(value)) {
        return [...acc, ...flattenObject(value, newKey)];
      }
      return [...acc, newKey];
    }, []);
  };

  const formatQueryParams = (params) => {
    return Object.entries(params)
      .filter(([, value]) => value)
      .flatMap(([key, value]) => {
        if (Array.isArray(value)) {
          // Handle array values by creating multiple entries with the same key
          return value.map(
            (item) => `${encodeURIComponent(key)}=${encodeURIComponent(String(item))}`,
          );
        }
        return [`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`];
      });
  };
  const formattedQueryParams = queryParams ? formatQueryParams(queryParams).join("&") : "";

  const separator = url.includes("?") ? "&" : "?";
  const queryString = [formattedQueryParams].filter(Boolean).join("&");

  return queryString ? `${url}${separator}${queryString}` : url;
}
