import React from "react";
import PaginatedPage from "../../../components/Common/PaginatedPage";
import { Link } from "react-router-dom";
import { SelectedLocationContext } from "../../../Context/LocationProvider";
import { cn } from "../../../utils/utils";

const CurrencyTransactions = () => {
  const columns = [
    {
      field: "client",
      headerName: "Client",
      width: 380,
      renderCell: (params) => {
        return (
          <Link
            to={
              params?.row?.clientType === "company"
                ? `/community/company/${params?.row?.company?._id}`
                : `/community/client/${params.row?.client?._id}`
            }
            className="flex flex-col gap-[5px] h-[80px] text-[16px]  justify-center hover:text-[#34B2C0]"
          >
            <div className="font-semibold">
              {params?.row?.clientType === "company"
                ? params?.row?.company?.name
                : params.row?.client?.name}
            </div>
          </Link>
        );
      },
    },
    {
      field: "currencyCode",
      headerName: "Currency",
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] font-semibold">
            {params?.row?.currencyCode}
          </div>
        );
      },
    },
    {
      field: "egpAmount",
      headerName: "EGP Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] font-bold">
            {params?.row?.egpAmount} EGP
          </div>
        );
      },
    },
    {
      field: "currencyAmount",
      headerName: "Paid Amount",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] font-bold">
            {params?.row?.currencyAmount} {params?.row?.currencyCode}
          </div>
        );
      },
    },
    {
      field: "serviceType",
      headerName: "Service Type",
      width: 150,
      renderCell: (params) => {
        return (
          <Link
            to={params?.row?.contractId && `/operations/rent-contract/${params?.row?.contractId}`}
            className={cn("flex gap-[10px] items-center text-[16px] font-bold cursor-text", {
              "hover:text-[#34B2C0] underline cursor-pointer": params?.row?.contractId,
            })}
          >
            {params?.row?.serviceType}
          </Link>
        );
      },
    },
  ];
  const { selectedLocation } = React.useContext(SelectedLocationContext);

  return (
    <PaginatedPage
      baseUrl={"/transactions-currencies"}
      columns={columns}
      title="Currency Transactions"
      queryParams={{ location: selectedLocation }}
      queryOptions={{ retry: 1 }}
    />
  );
};

export default CurrencyTransactions;
