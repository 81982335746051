import React, { useEffect, useState } from "react";
import { http } from "../../utils/httpCommon";
import { ArrowRight } from "lucide-react";
import { useQuery } from "@tanstack/react-query";

const CalculatedCurrencyAmount = ({ amount, toCurrency, hideArrow = false, setCurrencySymbol }) => {
  const { data: conversionResult } = useQuery({
    queryKey: ["currencies", "calculate", amount, toCurrency],
    queryFn: async () => {
      const response = await http.post("/currencies/calculate", {
        egpAmount: parseFloat(amount),
        toCurrency: toCurrency,
      });
      return response.data;
    },
    enabled: !!amount && !!toCurrency,
  });
  useEffect(() => {
    if (conversionResult) {
      setCurrencySymbol(conversionResult?.toCurrency);
    }
  }, [conversionResult]);
  return (
    <>
      {conversionResult && conversionResult?.toCurrency !== "EGP" && (
        <>
          {!hideArrow && <ArrowRight />}
          {conversionResult?.amount} {conversionResult?.toCurrency}
        </>
      )}
    </>
  );
};

export default CalculatedCurrencyAmount;
