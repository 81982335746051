import React, { useState, useEffect } from "react";
import { Layout } from "../../../components/Layout/Layout";
import { Button } from "../../../components/Button/Button";
import { http } from "../../../utils/httpCommon";
import { TextInput } from "../../../components/TextInput/TextInput";
import CustomTextInput from "../../../components/CustomTextInput/CustomTextInput";
import { useForm } from "react-hook-form";
import useFetchData from "../../../hooks/FetchData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DropFile from "../../../components/DropFile/DropFile";
import { useNavigate, useParams } from "react-router-dom";
import flag from "../../../assets/EgyptFlag.png";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { Controller } from "react-hook-form";
import useFetchPostData from "../../../hooks/FetchPostData";
import { toast } from "react-hot-toast";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const EditClient = () => {
  const navigator = useNavigate();
  //get all amenities
  const params = useParams();
  const { response: client, loading: loadingClient } = useFetchData(`/client/${params.id}`);

  const { response: locations, loading } = useFetchData("/location/me");

  const schema = yup
    .object({
      name: yup.string().required("Name is required"),
      phone: yup
        .string()
        .required("Phone number is required")
        .test("is-valid-phone", "Invalid phone number", (value) => {
          if (!value) return false;
          return isValidPhoneNumber(value);
        }),
      email: yup.string().email().required().typeError("Email is a required filed "),
      gender: yup.string().required("Gender is required").typeError("Gender is required"),
      // password: yup
      //   .string()
      //   .required()
      //   .typeError("Password is a required filed "),
      industry: yup.string(),
      birthdate: yup.string(),
      preferredCurrency: yup.string().required("Preferred Currency is required"),
    })
    .required();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [mainImage, setMainImages] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");

  const { response: industries, loading: loadingIndustries } = useFetchPostData(
    "/industry/all",
    JSON.stringify({
      page: 1,
      sortOrder: 1,
      limit: 10000,
    })
  );

  const { response: currencies, loading: loadingCurrencies } = useFetchData("/currencies?limit=500");

  const [submitError, setSubmitError] = useState("");
  const onSubmit = async (passedData) => {
    var data = new FormData();
    data.append("image", mainImage[0]);
    try {
      let res = await http.post("/image", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      try {
        var data2 = JSON.stringify({
          name: passedData.name,
          birthday: passedData.birthdate,
          email: passedData.email,
          locations: [passedData.location],
          profilePicture: res.data.url,
          phone: passedData.phone,
          gender: passedData.gender,
          industry: passedData.industry,
          preferredCurrency: passedData.preferredCurrency
        });

        await http.put(`/client/${client?._id}`, data2, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        toast.success("Client Edited successfully");
        // navigator("/community");
      } catch (error) {
        setSubmitError(error.response.data.message);
      }
    } catch (error) { }
  };

  const [birthdate, setBirthDate] = useState("");

  const handleChange = (newValue) => {
    setValue("birthdate", newValue);
    setBirthDate(newValue);
  };

  let genders = ["male", "female"];

  const [checkedGender, setCheckedGender] = useState("");
  const handleCheckedGender = (gender) => {
    if (checkedGender === gender) {
      setCheckedGender("");
    } else {
      setCheckedGender(gender);
    }
  };

  useEffect(() => {
    client?.profilePicture
      ? setMainImages([{ name: client?.profilePicture, preview: client?.profilePicture }])
      : setMainImages([]);

    setSelectedIndustry(client?.industry?._id);
    setSelectedCurrency(client?.preferredCurrency?._id || client?.preferredCurrency);
    setCheckedGender(client?.gender);
    setBirthDate(client?.birthday);
    setValue("gender", client?.gender);
  }, [loadingClient]);

  const handleChangeSelectIndustry = (event) => {
    setValue("industry", event.target.value);
    setSelectedIndustry(event.target.value);
  };

  const handelChangeCurrency = (event) => {
    setValue("preferredCurrency", event.target.value);
    setSelectedCurrency(event.target.value);
  };

  // mobile number
  let phone = watch("phone");
  const handleChangePhoneNumber = (value) => {
    setCustomValue("phone", value);
  };

  const setCustomValue = (id, value) => {
    setValue(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  useEffect(() => {
    if (!loadingClient) {
      setValue("phone", client?.phone);
    }
  }, [client, loadingClient]);

  return (
    <Layout>
      {!loading & !loadingClient ? (
        <div className="flex justify-center">
          <div className="w-[85%] bg-white px-14 py-4 m-2">
            <div className="flex flex-col">
              <div className="w-full flex justify-between">
                <span className=" text-[24px] font-bold my-3">Edit client</span>
              </div>
              <span className="text-[16px]">Upload profile pic</span>
              <span className="text-[16px]">Supported formate: JPEG, PNG</span>
            </div>
            <div className="flex gap-[20px] ">
              <div className="min-w-[40%] w-full h-[300px] mb-0 py-2">
                <DropFile
                  image={mainImage}
                  setImage={setMainImages}
                  height={"300px"}
                  width={"500px"}
                  message={"client image"}
                  bgColor="white"
                  fit="object-contain"
                />
              </div>
            </div>

            {/* form  */}
            <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-6">
                <TextInput
                  label={"Name*"}
                  defaultValue={client?.name}
                  {...register("name", { required: "input name is required" })}
                />
                <p className="text-red-500 text-[16px]">{errors.name?.message}</p>
              </div>

              <div className="mt-4">
                <TextInput
                  label={"Email*"}
                  defaultValue={client?.email}
                  {...register("email", { required: "Email  is required" })}
                />
                <p className="text-red-500 text-[16px]">{errors.email?.message}</p>
              </div>
              <div className="mt-4">
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      id="phone"
                      name="phone"
                      defaultCountry="EG"
                      placeholder="Enter your phone number"
                      value={phone}
                      onChange={handleChangePhoneNumber}
                      className={`custom-phone-input ${errors.phone ? "error" : ""}`}
                    />
                  )}
                />
                {errors.phone && (
                  <div className="text-rose-500 text-[14px]">{errors.phone.message}</div>
                )}
              </div>

              <div className="mt-4 w-full">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Birth date"
                    inputFormat="MM/DD/YYYY"
                    value={birthdate}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          width: "100%",
                          color: "#000000cc",
                          "& .MuiFormControl-root ": {
                            borderColor: "#EFEFEF",
                          },
                          "& .MuiInputBase-root": {
                            "& fieldset": {
                              borderColor: "#EFEFEF !important",
                            },
                            "&:hover fieldset": {
                              borderColor: "black !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#207beb !important",
                            },
                          },
                          "& .MuiFormLabel-root ": {
                            color: "#000000cc !important",
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

              <div className=" mt-4 flex flex-wrap gap-[5px] relative w-full h-fit py-3 bg-white border-[1px] border-[#EFEFEF] rounded-[5px] hover:border-[black] ">
                <div className="absolute bg-white p-1 -top-4 text-[16px] left-2 text-[#00000099] ">
                  Gender*
                </div>
                {genders?.map((item) => {
                  return (
                    <div
                      key={item}
                      className={` ml-2 mt-1 cursor-pointer hover:bg-[#32B3BE] hover:text-[white] transition-all
                    ${checkedGender === item ? "bg-[#32B3BE]" : ""} ${checkedGender === item ? "text-[#FFFFF1]" : ""
                        }
                      ${checkedGender === item ? "font-semibold" : "font-semibold"}
                     text-[#32B3BE] border-[1px] flex justify-center items-center p-2 border-[#32B3BE]
                    rounded-full w-fit min-w-[100px] text-[16px]  `}
                      onClick={() => {
                        item === checkedGender
                          ? setCustomValue("gender", null)
                          : setCustomValue("gender", item);
                        handleCheckedGender(item);
                      }}
                      {...register("gender", {
                        required: "Gender is required",
                      })}
                    >
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </div>
                  );
                })}
              </div>
              <p className="text-red-500 text-[16px]">{errors.gender?.message}</p>

              <div className="mt-4">
                <div>
                  <FormControl fullWidth>
                    <InputLabel id="Select Industry">Select Industry</InputLabel>
                    <Select
                      labelId="Select Location"
                      id="Select industry"
                      defaultValue={client?.industry}
                      value={selectedIndustry}
                      label="Select industry"
                      onChange={handleChangeSelectIndustry}
                    >
                      <MenuItem value="">
                        {" "}
                        <em>None</em>{" "}
                      </MenuItem>
                      {industries?.data?.map((l) => (
                        <MenuItem key={l._id} value={l._id}>
                          {l.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <p className="text-red-500 text-[16px]">{errors.industry?.message}</p>
                </div>
              </div>

              <div className="mt-4">
                <div>
                  <FormControl fullWidth>
                    <InputLabel id="Select preferred currency">Select preferred currency</InputLabel>
                    <Select
                      labelId="Select preferred currency"
                      id="select preferred currency"
                      defaultValue={client?.preferredCurrency?._id || client?.preferredCurrency}
                      value={selectedCurrency}
                      label="Select preferred currency"
                      onChange={handelChangeCurrency}
                    >
                      <MenuItem value="">
                        {" "}
                        <em>None</em>{" "}
                      </MenuItem>
                      {currencies?.map((c) => (
                        <MenuItem key={c._id} value={c._id}>
                          {c.name} - {c.code}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <p className="text-red-500 text-[16px]">{errors.preferredCurrency?.message}</p>
                </div>
              </div>


              <p className="text-red-500 my-3">{submitError}</p>
              <div className="mt-6 mb-8 gap-[50px] flex justify-end">
                <Button onClick={() => navigator("/community")} secondary={true} invert={true}>
                  Cancel
                </Button>
                <Button secondary={true} type="submit">
                  Edit Client
                </Button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <LoadingCircle />
      )}
    </Layout>
  );
};

export default EditClient;
