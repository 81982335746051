import React, { useCallback, useContext, useEffect, useState } from "react";
import deleteIcon from "../../../assets/icons/whiteTrash.png";
import phoneICon from "../../../assets/icons/call.png";
import { Link } from "react-router-dom";
import VoidRequest from "./VoidRequest";
import { http } from "../../../utils/httpCommon";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { debounce } from "lodash";
import Table from "../../../components/DataGrid/Table";
import { toast } from "react-hot-toast";
import { SelectedLocationContext } from "../../../Context/LocationProvider";
import { useAuthUser } from "react-auth-kit";
import { ADMIN_ROLES } from "../../../lib/enums";
import { useForm } from "react-hook-form";
import ModalComponent from "../../../components/Modal/Modal";
import { TextInput } from "../../../components/TextInput/TextInput";
import { ErrorMessage } from "@hookform/error-message";
import { Button } from "../../../components/Button/Button";
import { prettyNumber } from "../../../lib/prettyNumber";
import * as moment from "moment";

const Invoices = () => {
  const { selectedLocation } = useContext(SelectedLocationContext);
  const auth = useAuthUser();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const [searchQuery, setSearchQuery] = React.useState("");

  const handleChange = (event) => {
    debounced(event.target.value, 600);
    setSearchQuery(event.target.value);
  };

  ///onFilterChange
  const onFilterChange = useCallback(
    async (filterModel) => {
      try {
        let searchVal = filterModel?.items[0]?.value;
        if (searchVal?.length > 0) {
          setPageState((old) => ({
            ...old,
            isLoading: true,
          }));

          const res = await http.get(
            `/invoices?page=${pageState.page}&limit=${pageState.pageSize}&location=${selectedLocation}&search=${searchQuery}`
          );
          const rowsData = res.data?.data?.map((d) => ({
            id: d._id,
            ...d,
          }));
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: rowsData,
            total: res?.data?.metadata?.[0]?.count,
          }));
        } else {
          debounced();
        }
      } catch (error) {
        console.error(error);
        toast.error(error.response?.data?.message || error.message || "Something went wrong");
      }
    },
    [pageState.page, pageState.pageSize, selectedLocation, searchQuery]
  );

  const columns = [
    {
      field: "client",
      headerName: "Client",
      width: 180,
      // filterable: false,
      // editable: true,
      renderCell: (params) => {

        const clientId = params.row?.company?._id || params.row?.client?._id;
        const clientName = params.row?.company?.name || params.row?.client?.name;
        const clientPhone = params.row?.company?.phone || params.row?.client?.phone;
        const profileUrl = (params.row?.company?._id) ? `/community/company/${clientId}` : `/community/client/${clientId}`

        return (
          <Link
            to={profileUrl}
            className="flex flex-col gap-[5px] h-[80px] text-[16px]  justify-center hover:text-[#34B2C0]"
          >
            <div className="font-semibold">{clientName}</div>

            {clientPhone && (
              <div className="flex items-center">
                <img src={phoneICon} className="w-[13.5px]" alt={clientName} />
                {clientPhone}
              </div>
            )}
          </Link>
        );
      },
    },
    {
      field: "location",
      headerName: "Locations",
      width: 150,

      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] ">
            {params?.row?.location?.name}
          </div>
        );
      },
    },
    {
      field: "services",
      headerName: "Services",
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">{params?.row?.service}</div>
        );
      },
      // editable: true,
    },

    {
      field: "Payment Method",
      headerName: "Payment Method",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {params?.row?.paymentMethodName}
          </div>
        );
      },
      // editable: true,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {prettyNumber(params?.row?.amount)} EGP
          </div>
        );
      },
      // editable: true,
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 150,
    //   renderCell: (params) => {
    //     return (
    //       <div className="flex gap-[10px] items-center w-fit text-[16px]">
    //         {params?.row?.status === "Confirmed" ? (
    //           <div className=" text-[#00AD3B] bg-[#00ad3b1a]  font-semibold py-2 px-4 flex  justify-center items-center rounded-[25px]  ">
    //             {params?.row?.status}
    //           </div>
    //         ) : params?.row?.status === "Pending" ? (
    //           <div className=" text-[#34B2C0] bg-[#34b2c033]  font-semibold py-2 px-4 flex  justify-center items-center rounded-[25px]  ">
    //             {params?.row?.status}
    //           </div>
    //         ) : (
    //           <div className=" text-[#797979] bg-[#EFEFEF]  font-semibold py-2 px-4 flex  justify-center items-center rounded-[25px]  ">
    //             {params?.row?.status}
    //           </div>
    //         )}
    //       </div>
    //     );
    //   },
    //   // editable: true,
    // },

    {
      field: "date",
      headerName: "Date",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {moment(params.row.date).format('YYYY-MM-DD hh:mm A')}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {auth().role === ADMIN_ROLES.SA && (
              <div className="flex gap-2">
                <div className="text-[#797979] flex items-center gap-2 font-extrabold text-[16px]">
                  <div className="flex gap-[15px] bg-red-500 w-max h-fit rounded-[10px]">
                    <button
                      className=" cursor-pointer hover:bg-red-700 p-2 rounded-[10px] "
                      onClick={() => {
                        handleDelete(params.row);
                      }}
                    >
                      <img className="w-[20px] h-[20px]" src={deleteIcon} alt="Delete" />
                    </button>
                  </div>
                  Delete
                </div>
              </div>
            )}
          </>
        );
      },
      // editable: true,
    },
  ];

  const fetchData = async (searchValues) => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const res = await http.get(
        `/invoices?page=${pageState.page}&limit=${pageState.pageSize}&location=${selectedLocation}&search=${searchQuery}`
      );
      const rowsData = res.data?.data?.map((d) => ({
        id: d._id,
        ...d,
      }));
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        total: res?.data?.metadata?.[0]?.count,
      }));
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    }
  };
  const debounced = useCallback(debounce(fetchData, 1000), []);

  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize, selectedLocation, searchQuery]);

  //handle add new
  // const [openAddModal, setOpenAddModal] = useState(false);
  // const handleActionButton = () => {
  //   setOpenAddModal(true);
  // };

  //handle delete
  const [openedVoidRequest, setOpenedVoidRequest] = useState(false);
  const [voidRequestItem, setVoidRequestItem] = useState({});
  const handleVoidRequest = async (item) => {
    setVoidRequestItem(item);
    setOpenedVoidRequest(true);
  };

  const [toDelete, setToDelete] = useState();
  const [deleteModal, toggleDeleteModal] = useState(false);
  const handleDelete = async (item) => {
    setToDelete(item?._id);
    toggleDeleteModal(true);
  };

  return (
    <div>
      <VoidRequest
        voidRequestItem={voidRequestItem}
        opened={openedVoidRequest}
        setOpened={setOpenedVoidRequest}
        fetchData={fetchData}
      />

      <DeleteInvoiceModal
        fetchData={fetchData}
        opened={deleteModal}
        setOpened={toggleDeleteModal}
        item={toDelete}
      />

      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={pageState.total}
        isLoading={pageState.isLoading}
        data={pageState?.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        keys={["id", "name", "phone", "email", "location", "role"]}
        title={"Invoices"}
        onFilterChange={onFilterChange}
        // actionButton={"Reconsiliation request"}
        // handleActionButton={handleActionButton}
        removeActionButton={true}
      />
    </div>
  );
};

export default Invoices;

function DeleteInvoiceModal({ fetchData, opened, setOpened, item }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    adminPassword: "",
  });

  const [submitLoading, setSubmitLoading] = useState(false);

  const onSubmit = async (passedData) => {
    try {
      setSubmitLoading(true);
      if (!item) {
        toast.error("Item id is empty, Contact dev team.");
        return false;
      }

      const r = await http.delete(`/invoices`, {
        data: JSON.stringify({
          invoiceId: item,
          adminPassword: passedData.adminPassword,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(r.data);
      toast.success("Invoice deleted successfully");

      reset();
      setOpened(false);
      fetchData();
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <ModalComponent opened={opened} setOpened={setOpened}>
      <div className="text-[24px] font-bold">Delete Transaction</div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-center items-center gap-[40px] mt-4"
      >
        <div className="w-[100%]">
          <TextInput
            label={"Password"}
            type="password"
            {...register("adminPassword", { required: true })}
          ></TextInput>
          <ErrorMessage
            name="adminPassword"
            errors={errors}
            render={({ message }) => <p className="text-red-500">{message}</p>}
          />
        </div>

        <div className="flex justify-around  w-full">
          <Button secondary={true} invert={true} onClick={() => setOpened(false)}>
            Cancel
          </Button>
          <Button loading={submitLoading} secondary={true} type="submit">
            Confirm
          </Button>
        </div>
      </form>
    </ModalComponent>
  );
}
